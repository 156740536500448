@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@font-face {
  font-family: "AvenirNext";
  src: url("../../fonts/AvenirNext/400-Regular/avenir-next-regular.svg") format("svg"), url("../../fonts/AvenirNext/400-Regular/avenir-next-regular.woff") format("woff"), url("../../fonts/AvenirNext/400-Regular/avenir-next-regular.woff2") format("woff2"), url("../../fonts/AvenirNext/400-Regular/avenir-next-regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirNext";
  src: url("../../fonts/AvenirNext/500-Medium/avenir-next-medium.svg") format("svg"), url("../../fonts/AvenirNext/500-Medium/avenir-next-medium.woff") format("woff"), url("../../fonts/AvenirNext/500-Medium/avenir-next-medium.woff2") format("woff2"), url("../../fonts/AvenirNext/500-Medium/avenir-next-medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirNext";
  src: url("../../fonts/AvenirNext/600-Demi/avenir-next-demi.woff") format("woff"), url("../../fonts/AvenirNext/600-Demi/avenir-next-demi.woff2") format("woff2"), url("../../fonts/AvenirNext/600-Demi/avenir-next-demi.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirNext";
  src: url("../../fonts/AvenirNext/700-Bold/avenir-next-bold.woff") format("woff"), url("../../fonts/AvenirNext/700-Bold/avenir-next-bold.woff2") format("woff2"), url("../../fonts/AvenirNext/700-Bold/avenir-next-bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px AvenirNext;
  letter-spacing: 0em;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px AvenirNext;
  letter-spacing: 0.0075em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px AvenirNext;
  letter-spacing: 0.0094em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 500 15px / 24px AvenirNext;
  letter-spacing: 0.0067em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px AvenirNext;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px AvenirNext;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px AvenirNext;
  letter-spacing: 0.0179em;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px AvenirNext;
  letter-spacing: 0.0179em;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px AvenirNext;
  letter-spacing: 0.0333em;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px AvenirNext;
  letter-spacing: -0.0134em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px AvenirNext;
  letter-spacing: -0.0089em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px AvenirNext;
  letter-spacing: 0em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px AvenirNext;
  letter-spacing: 0.0074em;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px AvenirNext;
  letter-spacing: 0.0179em;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: 500;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px AvenirNext;
  letter-spacing: 0.0075em;
}

.mat-expansion-panel-header {
  font-family: AvenirNext;
  font-size: 15px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px AvenirNext;
  letter-spacing: 0.0179em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: AvenirNext;
  letter-spacing: 1.5px;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: AvenirNext;
  font-size: 12px;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slider-thumb-label-text {
  font-size: 12px;
  font-weight: 500;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-label, .mat-tab-link {
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px AvenirNext;
  letter-spacing: 0.0075em;
  margin: 0;
}

.mat-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px AvenirNext;
  letter-spacing: 0.0179em;
}

.mat-simple-snackbar {
  font-family: AvenirNext;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

body {
  --primary-color: #800202;
  --primary-lighter-color: #fde3c4;
  --primary-darker-color: #f58825;
  --text-primary-color: rgba(0, 0, 0, 0.87);
  --text-primary-lighter-color: rgba(0, 0, 0, 0.87);
  --text-primary-darker-color: rgba(0, 0, 0, 0.87);
}

body {
  --accent-color: #555659;
  --accent-lighter-color: #cccccd;
  --accent-darker-color: #3b3c3e;
  --text-accent-color: #ffffff;
  --text-accent-lighter-color: rgba(0, 0, 0, 0.87);
  --text-accent-darker-color: #ffffff;
}

body {
  --warn-color: #c96664;
  --warn-lighter-color: #efd1d1;
  --warn-darker-color: #b54948;
  --text-warn-color: rgba(0, 0, 0, 0.87);
  --text-warn-lighter-color: rgba(0, 0, 0, 0.87);
  --text-warn-darker-color: #ffffff;
}

.mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-option {
  color: white;
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #800202;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #555659;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #c96664;
}

.mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}

.mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.mat-pseudo-checkbox::after {
  color: #171717;
}

.mat-pseudo-checkbox-disabled {
  color: #686868;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #800202;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #555659;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #c96664;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}

.mat-app-background {
  background-color: #171717;
  color: white;
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: #242424;
  color: white;
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #242424;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #800202;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #555659;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #c96664;
}

.mat-badge-disabled .mat-badge-content {
  background: #5d5d5d;
  color: rgba(255, 255, 255, 0.5);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #242424;
  color: white;
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #800202;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #555659;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #c96664;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #800202;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #555659;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #c96664;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: #ffffff;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: white;
  background-color: #242424;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: rgba(0, 0, 0, 0.87);
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: rgba(0, 0, 0, 0.87);
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #800202;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #555659;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #c96664;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: white;
  background: #242424;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #3e3e3e;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #3e3e3e;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #3e3e3e;
}

.mat-button-toggle-checked {
  background-color: #4a4a4a;
  color: rgba(255, 255, 255, 0.7);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}

.mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: #313131;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #242424;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #646464;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #3e3e3e;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: #242424;
  color: white;
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}

.mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}

.mat-checkbox-checkmark {
  fill: #171717;
}

.mat-checkbox-checkmark-path {
  stroke: #171717 !important;
}

.mat-checkbox-mixedmark {
  background-color: #171717;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #800202;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #555659;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #c96664;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}

.mat-checkbox .mat-ripple-element {
  background-color: #ffffff;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #800202;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #555659;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #c96664;
}

.mat-chip.mat-standard-chip {
  background-color: #4a4a4a;
  color: white;
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: #ffffff;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #800202;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #c96664;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #555659;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: #242424;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}

.mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}

.mat-cell, .mat-footer-cell {
  color: white;
}

.mat-calendar-arrow {
  fill: white;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}

.mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}

.mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}

.mat-calendar-body-in-range::before {
  background: rgba(128, 2, 2, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(128, 2, 2, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(128, 2, 2, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #800202;
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(128, 2, 2, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(128, 2, 2, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(128, 2, 2, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #242424;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(85, 86, 89, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(85, 86, 89, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(85, 86, 89, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #555659;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(85, 86, 89, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(85, 86, 89, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(85, 86, 89, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(201, 102, 100, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(201, 102, 100, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(201, 102, 100, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #c96664;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(201, 102, 100, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(201, 102, 100, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(201, 102, 100, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #800202;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #555659;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #c96664;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #242424;
  color: white;
}

.mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}

.mat-expansion-panel {
  background: #242424;
  color: white;
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #242424;
  }
}
.mat-expansion-panel-header-title {
  color: white;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}

.mat-hint {
  color: rgba(255, 255, 255, 0.7);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #800202;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #555659;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #c96664;
}

.mat-focused .mat-form-field-required-marker {
  color: #555659;
}

.mat-form-field-ripple {
  background-color: white;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #800202;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #555659;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #c96664;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #800202;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #555659;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #c96664;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #c96664;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #c96664;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #c96664;
}

.mat-error {
  color: #c96664;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #800202;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #555659;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #c96664;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #c96664;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}

.mat-icon.mat-primary {
  color: #800202;
}
.mat-icon.mat-accent {
  color: #555659;
}
.mat-icon.mat-warn {
  color: #c96664;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}

.mat-input-element {
  caret-color: #800202;
}
.mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #555659;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #c96664;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #c96664;
}

.mat-list-base .mat-list-item {
  color: white;
}
.mat-list-base .mat-list-option {
  color: white;
}
.mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #313131;
  color: rgba(255, 255, 255, 0.5);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}

.mat-menu-panel {
  background: #242424;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: white;
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: white;
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}

.mat-paginator {
  background: #242424;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid white;
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #311212;
}

.mat-progress-bar-buffer {
  background-color: #311212;
}

.mat-progress-bar-fill::after {
  background-color: #800202;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #272728;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #272728;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #555659;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #442b2a;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #442b2a;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #c96664;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #800202;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #555659;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #c96664;
}

.mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #800202;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #800202;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #555659;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #555659;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #c96664;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #c96664;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.mat-radio-button .mat-ripple-element {
  background-color: #ffffff;
}

.mat-select-value {
  color: white;
}

.mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}

.mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}

.mat-select-panel {
  background: #242424;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #800202;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #555659;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #c96664;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #c96664;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}

.mat-drawer-container {
  background-color: #171717;
  color: white;
}

.mat-drawer {
  background-color: #242424;
  color: white;
}
.mat-drawer.mat-drawer-push {
  background-color: #242424;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(219, 219, 219, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #555659;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(85, 86, 89, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #555659;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #800202;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(128, 2, 2, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #800202;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #c96664;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(201, 102, 100, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #c96664;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}

.mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}

.mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #800202;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(128, 2, 2, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #555659;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(85, 86, 89, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #c96664;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(201, 102, 100, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #800202;
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #555659;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #c96664;
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #c96664;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #c96664;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: #242424;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #bdbdbd;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: white;
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}

.mat-tab-header-pagination-chevron {
  border-color: white;
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(253, 227, 196, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #800202;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(204, 204, 205, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #555659;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(239, 209, 209, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #c96664;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(253, 227, 196, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #800202;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(204, 204, 205, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #555659;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(239, 209, 209, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #c96664;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}

.mat-toolbar {
  background: #242424;
  color: white;
}
.mat-toolbar.mat-primary {
  background: #800202;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-accent {
  background: #555659;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #c96664;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(74, 74, 74, 0.9);
}

.mat-tree {
  background: #242424;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: white;
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: inherit;
}

.theme-alternate .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-alternate .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-option:hover:not(.mat-option-disabled), .theme-alternate .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(23, 23, 23, 0.04);
}
.theme-alternate .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(23, 23, 23, 0.04);
}
.theme-alternate .mat-option.mat-active {
  background: rgba(23, 23, 23, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #800202;
}
.theme-alternate .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #555659;
}
.theme-alternate .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #c96664;
}
.theme-alternate .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-pseudo-checkbox::after {
  color: white;
}
.theme-alternate .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.theme-alternate .mat-primary .mat-pseudo-checkbox-checked,
.theme-alternate .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #800202;
}
.theme-alternate .mat-pseudo-checkbox-checked,
.theme-alternate .mat-pseudo-checkbox-indeterminate,
.theme-alternate .mat-accent .mat-pseudo-checkbox-checked,
.theme-alternate .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #555659;
}
.theme-alternate .mat-warn .mat-pseudo-checkbox-checked,
.theme-alternate .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #c96664;
}
.theme-alternate .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-alternate .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.theme-alternate .mat-app-background, .theme-alternate.mat-app-background {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-alternate .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #800202;
}
.cdk-high-contrast-active .theme-alternate .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-alternate .mat-badge-accent .mat-badge-content {
  background: #555659;
  color: white;
}
.theme-alternate .mat-badge-warn .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #c96664;
}
.theme-alternate .mat-badge-disabled .mat-badge-content {
  background: #bdbdbd;
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-button, .theme-alternate .mat-icon-button, .theme-alternate .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.theme-alternate .mat-button.mat-primary, .theme-alternate .mat-icon-button.mat-primary, .theme-alternate .mat-stroked-button.mat-primary {
  color: #800202;
}
.theme-alternate .mat-button.mat-accent, .theme-alternate .mat-icon-button.mat-accent, .theme-alternate .mat-stroked-button.mat-accent {
  color: #555659;
}
.theme-alternate .mat-button.mat-warn, .theme-alternate .mat-icon-button.mat-warn, .theme-alternate .mat-stroked-button.mat-warn {
  color: #c96664;
}
.theme-alternate .mat-button.mat-primary.mat-button-disabled, .theme-alternate .mat-button.mat-accent.mat-button-disabled, .theme-alternate .mat-button.mat-warn.mat-button-disabled, .theme-alternate .mat-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-icon-button.mat-primary.mat-button-disabled, .theme-alternate .mat-icon-button.mat-accent.mat-button-disabled, .theme-alternate .mat-icon-button.mat-warn.mat-button-disabled, .theme-alternate .mat-icon-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-primary.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-accent.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-warn.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.theme-alternate .mat-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #800202;
}
.theme-alternate .mat-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #555659;
}
.theme-alternate .mat-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #c96664;
}
.theme-alternate .mat-button.mat-button-disabled .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.theme-alternate .mat-button .mat-ripple-element, .theme-alternate .mat-icon-button .mat-ripple-element, .theme-alternate .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.theme-alternate .mat-button-focus-overlay {
  background: black;
}
.theme-alternate .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-flat-button, .theme-alternate .mat-raised-button, .theme-alternate .mat-fab, .theme-alternate .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  color: white;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-flat-button.mat-primary.mat-button-disabled, .theme-alternate .mat-flat-button.mat-accent.mat-button-disabled, .theme-alternate .mat-flat-button.mat-warn.mat-button-disabled, .theme-alternate .mat-flat-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-raised-button.mat-primary.mat-button-disabled, .theme-alternate .mat-raised-button.mat-accent.mat-button-disabled, .theme-alternate .mat-raised-button.mat-warn.mat-button-disabled, .theme-alternate .mat-raised-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-fab.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  background-color: #800202;
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  background-color: #555659;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  background-color: #c96664;
}
.theme-alternate .mat-flat-button.mat-primary.mat-button-disabled, .theme-alternate .mat-flat-button.mat-accent.mat-button-disabled, .theme-alternate .mat-flat-button.mat-warn.mat-button-disabled, .theme-alternate .mat-flat-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-raised-button.mat-primary.mat-button-disabled, .theme-alternate .mat-raised-button.mat-accent.mat-button-disabled, .theme-alternate .mat-raised-button.mat-warn.mat-button-disabled, .theme-alternate .mat-raised-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-fab.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(23, 23, 23, 0.12);
}
.theme-alternate .mat-flat-button.mat-primary .mat-ripple-element, .theme-alternate .mat-raised-button.mat-primary .mat-ripple-element, .theme-alternate .mat-fab.mat-primary .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-alternate .mat-flat-button.mat-accent .mat-ripple-element, .theme-alternate .mat-raised-button.mat-accent .mat-ripple-element, .theme-alternate .mat-fab.mat-accent .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-warn .mat-ripple-element, .theme-alternate .mat-raised-button.mat-warn .mat-ripple-element, .theme-alternate .mat-fab.mat-warn .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-alternate .mat-stroked-button:not([class*=mat-elevation-z]), .theme-alternate .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-alternate .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-alternate .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-alternate .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.theme-alternate .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.theme-alternate .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.theme-alternate [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.theme-alternate .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.theme-alternate .mat-button-toggle-checked {
  background-color: #cccccc;
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #e6e6e6;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #b3b3b3;
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-alternate .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.theme-alternate .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-checkbox-checkmark {
  fill: white;
}
.theme-alternate .mat-checkbox-checkmark-path {
  stroke: white !important;
}
.theme-alternate .mat-checkbox-mixedmark {
  background-color: white;
}
.theme-alternate .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #800202;
}
.theme-alternate .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #555659;
}
.theme-alternate .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #c96664;
}
.theme-alternate .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .theme-alternate .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.theme-alternate .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.theme-alternate .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #800202;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #555659;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #c96664;
}
.theme-alternate .mat-chip.mat-standard-chip {
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip::after {
  background: black;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #800202;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #c96664;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #555659;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-table {
  background: white;
}
.theme-alternate .mat-table thead, .theme-alternate .mat-table tbody, .theme-alternate .mat-table tfoot,
.theme-alternate mat-header-row, .theme-alternate mat-row, .theme-alternate mat-footer-row,
.theme-alternate [mat-header-row], .theme-alternate [mat-row], .theme-alternate [mat-footer-row],
.theme-alternate .mat-table-sticky {
  background: inherit;
}
.theme-alternate mat-row, .theme-alternate mat-header-row, .theme-alternate mat-footer-row,
.theme-alternate th.mat-header-cell, .theme-alternate td.mat-cell, .theme-alternate td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-cell, .theme-alternate .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-datepicker-toggle,
.theme-alternate .mat-datepicker-content .mat-calendar-next-button,
.theme-alternate .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-calendar-table-header,
.theme-alternate .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-calendar-body-cell-content,
.theme-alternate .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.theme-alternate .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.theme-alternate .mat-calendar-body-in-range::before {
  background: rgba(128, 2, 2, 0.2);
}
.theme-alternate .mat-calendar-body-comparison-identical,
.theme-alternate .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-alternate .mat-calendar-body-comparison-bridge-start::before,
.theme-alternate [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(128, 2, 2, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-calendar-body-comparison-bridge-end::before,
.theme-alternate [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(128, 2, 2, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-alternate .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-alternate .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-alternate .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-alternate .mat-calendar-body-selected {
  background-color: #800202;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(128, 2, 2, 0.4);
}
.theme-alternate .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.theme-alternate .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-alternate .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(128, 2, 2, 0.3);
}
@media (hover: hover) {
  .theme-alternate .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(128, 2, 2, 0.3);
  }
}
.theme-alternate .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(85, 86, 89, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-alternate .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(85, 86, 89, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-alternate .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(85, 86, 89, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #555659;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(85, 86, 89, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-alternate .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(85, 86, 89, 0.3);
}
@media (hover: hover) {
  .theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(85, 86, 89, 0.3);
  }
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(201, 102, 100, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-alternate .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(201, 102, 100, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-alternate .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(201, 102, 100, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #c96664;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(201, 102, 100, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-alternate .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(201, 102, 100, 0.3);
}
@media (hover: hover) {
  .theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(201, 102, 100, 0.3);
  }
}
.theme-alternate .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-datepicker-toggle-active {
  color: #800202;
}
.theme-alternate .mat-datepicker-toggle-active.mat-accent {
  color: #555659;
}
.theme-alternate .mat-datepicker-toggle-active.mat-warn {
  color: #c96664;
}
.theme-alternate .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(23, 23, 23, 0.04);
}
@media (hover: none) {
  .theme-alternate .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.theme-alternate .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-expansion-panel-header-description,
.theme-alternate .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-alternate .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-alternate .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label {
  color: #800202;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #555659;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #c96664;
}
.theme-alternate .mat-focused .mat-form-field-required-marker {
  color: #555659;
}
.theme-alternate .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #800202;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #555659;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #c96664;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #800202;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #555659;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #c96664;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #c96664;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #c96664;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #c96664;
}
.theme-alternate .mat-error {
  color: #c96664;
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.theme-alternate .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.theme-alternate .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #800202;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #555659;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #c96664;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #c96664;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.theme-alternate .mat-icon.mat-primary {
  color: #800202;
}
.theme-alternate .mat-icon.mat-accent {
  color: #555659;
}
.theme-alternate .mat-icon.mat-warn {
  color: #c96664;
}
.theme-alternate .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-input-element:disabled,
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-input-element {
  caret-color: #800202;
}
.theme-alternate .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.theme-alternate .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.theme-alternate .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.theme-alternate .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.theme-alternate .mat-form-field.mat-accent .mat-input-element {
  caret-color: #555659;
}
.theme-alternate .mat-form-field.mat-warn .mat-input-element,
.theme-alternate .mat-form-field-invalid .mat-input-element {
  caret-color: #c96664;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #c96664;
}
.theme-alternate .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-list-base .mat-list-item-disabled {
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-list-option:hover, .theme-alternate .mat-list-option:focus,
.theme-alternate .mat-nav-list .mat-list-item:hover,
.theme-alternate .mat-nav-list .mat-list-item:focus,
.theme-alternate .mat-action-list .mat-list-item:hover,
.theme-alternate .mat-action-list .mat-list-item:focus {
  background: rgba(23, 23, 23, 0.04);
}
.theme-alternate .mat-list-single-selected-option, .theme-alternate .mat-list-single-selected-option:hover, .theme-alternate .mat-list-single-selected-option:focus {
  background: rgba(23, 23, 23, 0.12);
}
.theme-alternate .mat-menu-panel {
  background: white;
}
.theme-alternate .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-menu-item[disabled],
.theme-alternate .mat-menu-item[disabled] .mat-menu-submenu-icon,
.theme-alternate .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-menu-item .mat-icon-no-color,
.theme-alternate .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-menu-item:hover:not([disabled]),
.theme-alternate .mat-menu-item.cdk-program-focused:not([disabled]),
.theme-alternate .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-alternate .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(23, 23, 23, 0.04);
}
.theme-alternate .mat-paginator {
  background: white;
}
.theme-alternate .mat-paginator,
.theme-alternate .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-paginator-decrement,
.theme-alternate .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-paginator-first,
.theme-alternate .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-icon-button[disabled] .mat-paginator-decrement,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-increment,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-first,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-progress-bar-background {
  fill: #dfc0c0;
}
.theme-alternate .mat-progress-bar-buffer {
  background-color: #dfc0c0;
}
.theme-alternate .mat-progress-bar-fill::after {
  background-color: #800202;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #d5d5d6;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #d5d5d6;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #555659;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f2d9d8;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f2d9d8;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #c96664;
}
.theme-alternate .mat-progress-spinner circle, .theme-alternate .mat-spinner circle {
  stroke: #800202;
}
.theme-alternate .mat-progress-spinner.mat-accent circle, .theme-alternate .mat-spinner.mat-accent circle {
  stroke: #555659;
}
.theme-alternate .mat-progress-spinner.mat-warn circle, .theme-alternate .mat-spinner.mat-warn circle {
  stroke: #c96664;
}
.theme-alternate .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #800202;
}
.theme-alternate .mat-radio-button.mat-primary .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #800202;
}
.theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #555659;
}
.theme-alternate .mat-radio-button.mat-accent .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #555659;
}
.theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #c96664;
}
.theme-alternate .mat-radio-button.mat-warn .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #c96664;
}
.theme-alternate .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.theme-alternate .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.theme-alternate .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-select-panel {
  background: white;
}
.theme-alternate .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(23, 23, 23, 0.12);
}
.theme-alternate .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #800202;
}
.theme-alternate .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #555659;
}
.theme-alternate .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #c96664;
}
.theme-alternate .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #c96664;
}
.theme-alternate .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-drawer-container {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-drawer.mat-drawer-push {
  background-color: white;
}
.theme-alternate .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #555659;
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(85, 86, 89, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #555659;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #800202;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(128, 2, 2, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #800202;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #c96664;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(201, 102, 100, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #c96664;
}
.theme-alternate .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.theme-alternate .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.theme-alternate .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.theme-alternate .mat-slider.mat-primary .mat-slider-track-fill,
.theme-alternate .mat-slider.mat-primary .mat-slider-thumb,
.theme-alternate .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #800202;
}
.theme-alternate .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(128, 2, 2, 0.2);
}
.theme-alternate .mat-slider.mat-accent .mat-slider-track-fill,
.theme-alternate .mat-slider.mat-accent .mat-slider-thumb,
.theme-alternate .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #555659;
}
.theme-alternate .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(85, 86, 89, 0.2);
}
.theme-alternate .mat-slider.mat-warn .mat-slider-track-fill,
.theme-alternate .mat-slider.mat-warn .mat-slider-thumb,
.theme-alternate .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #c96664;
}
.theme-alternate .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(201, 102, 100, 0.2);
}
.theme-alternate .mat-slider:hover .mat-slider-track-background,
.theme-alternate .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-slider.mat-slider-disabled .mat-slider-track-background,
.theme-alternate .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.theme-alternate .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.theme-alternate .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.theme-alternate .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.theme-alternate .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.theme-alternate .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.theme-alternate .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.theme-alternate .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .theme-alternate .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .theme-alternate .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.theme-alternate .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.theme-alternate .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-step-header.cdk-keyboard-focused, .theme-alternate .mat-step-header.cdk-program-focused, .theme-alternate .mat-step-header:hover:not([aria-disabled]), .theme-alternate .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(23, 23, 23, 0.04);
}
.theme-alternate .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-alternate .mat-step-header:hover {
    background: none;
  }
}
.theme-alternate .mat-step-header .mat-step-label,
.theme-alternate .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.theme-alternate .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-step-header .mat-step-icon-selected,
.theme-alternate .mat-step-header .mat-step-icon-state-done,
.theme-alternate .mat-step-header .mat-step-icon-state-edit {
  background-color: #800202;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-alternate .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-alternate .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-alternate .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #555659;
  color: white;
}
.theme-alternate .mat-step-header.mat-warn .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-alternate .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-alternate .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #c96664;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #c96664;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-error {
  color: #c96664;
}
.theme-alternate .mat-stepper-horizontal, .theme-alternate .mat-stepper-vertical {
  background-color: white;
}
.theme-alternate .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-horizontal-stepper-header::before,
.theme-alternate .mat-horizontal-stepper-header::after,
.theme-alternate .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-sort-header-arrow {
  color: #757575;
}
.theme-alternate .mat-tab-nav-bar,
.theme-alternate .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-tab-group-inverted-header .mat-tab-nav-bar,
.theme-alternate .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.theme-alternate .mat-tab-label, .theme-alternate .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.theme-alternate .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(253, 227, 196, 0.3);
}
.theme-alternate .mat-tab-group.mat-primary .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #800202;
}
.theme-alternate .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(204, 204, 205, 0.3);
}
.theme-alternate .mat-tab-group.mat-accent .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #555659;
}
.theme-alternate .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(239, 209, 209, 0.3);
}
.theme-alternate .mat-tab-group.mat-warn .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #c96664;
}
.theme-alternate .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(253, 227, 196, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #800202;
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(204, 204, 205, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #555659;
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(239, 209, 209, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #c96664;
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.theme-alternate .mat-toolbar {
  background: #f2f2f2;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-toolbar.mat-primary {
  background: #800202;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-toolbar.mat-accent {
  background: #555659;
  color: white;
}
.theme-alternate .mat-toolbar.mat-warn {
  background: #c96664;
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-toolbar .mat-form-field-underline,
.theme-alternate .mat-toolbar .mat-form-field-ripple,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-alternate .mat-toolbar .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-select-value,
.theme-alternate .mat-toolbar .mat-select-arrow,
.theme-alternate .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-alternate .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-alternate .mat-tooltip {
  background: rgba(74, 74, 74, 0.9);
}
.theme-alternate .mat-tree {
  background: white;
}
.theme-alternate .mat-tree-node,
.theme-alternate .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-simple-snackbar-action {
  color: #555659;
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
}
.material-icons .mat-badge-content {
  font-family: "AvenirNext";
}

.transparent-color.mat-select-value {
  color: transparent !important;
}

mat-form-field.mat-form-field .transparent-color.mat-select-value {
  color: transparent;
}

.material-icons-outlined,
.material-icons.material-icons--outlined,
.material-icons-two-tone,
.material-icons.material-icons--two-tone,
.material-icons-round,
.material-icons.material-icons--round,
.material-icons-sharp,
.material-icons.material-icons--sharp {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.material-icons-outlined,
.material-icons.material-icons--outlined {
  font-family: "Material Icons Outlined";
}

.material-icons-two-tone,
.material-icons.material-icons--two-tone {
  font-family: "Material Icons Two Tone";
}

.material-icons-round,
.material-icons.material-icons--round {
  font-family: "Material Icons Round";
}

.material-icons-sharp,
.material-icons.material-icons--sharp {
  font-family: "Material Icons Sharp";
}

.bg-tux {
  background: #000000 !important;
}

.bg-white {
  background: #ffffff !important;
}

.font-black {
  color: #000000 !important;
}

.font-white {
  color: #ffffff !important;
}

.font-light-grey {
  color: #818284 !important;
}

.font-grey-4 {
  color: #9e9e9e !important;
}

.font-grey-5 {
  color: #cbcbcb !important;
}

.font-grey-6 {
  color: #f5f5f5 !important;
}

.font-orange {
  color: #800202 !important;
}

.font-red {
  color: #c96664 !important;
}

.font-green {
  color: #84d27c !important;
}

body {
  margin: 0;
  background: #171717;
  color: #000000;
}

.page-wrapper {
  background-color: #000000;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.page-container {
  width: 100%;
  height: 100%;
  margin: 0;
}

.page-container-light {
  width: 100%;
  height: 100%;
  min-height: 93vh;
  margin: 0;
  background-color: #ffffff !important;
  color: #000000 !important;
}

.page-content {
  height: 100%;
  width: 95%;
  margin: auto;
}

.page-title-section {
  background: #171717;
  padding: 0 10%;
  padding-top: 1rem;
  height: 10.875rem;
  box-sizing: border-box;
}
.page-title-section-team {
  height: 14.281rem;
}
.page-title-section-results {
  padding-top: 0;
}
.page-title-section-results .page-title-text {
  padding-left: 0;
  width: 100%;
}

.page-title-start {
  white-space: nowrap;
  overflow: hidden;
  margin-right: 2rem;
}

.page-title-text {
  padding-left: 25px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  box-sizing: border-box;
  width: calc(100% - 112px);
}
.page-title-text .page-title-h1 {
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  margin-bottom: 1px;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.page-title-text .page-title-subheader {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.page-title-brand-logo {
  display: block;
  width: 112px;
  height: 112px;
  background-color: #ffffff;
  text-align: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}
.page-title-brand-logo img {
  max-height: 90px;
  max-width: 90px;
}

.page-container-with-footer-light {
  width: 100%;
  height: 100%;
  height: calc(100vh - 5rem);
  overflow-y: auto;
  margin: 0;
  background-color: #ffffff !important;
  color: #000000 !important;
}

.lv-breadcrumb {
  height: 2.75rem;
  margin: 0 -0.438rem 1.625rem -0.438rem;
  color: #800202;
}
.lv-breadcrumb button {
  color: #800202;
  padding: 0 0.438rem;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}

.text-bolder {
  font-weight: bolder;
}

.cursor-pointer {
  cursor: pointer;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.padding-0 {
  padding: 0px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-25 {
  padding: 25px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-25 {
  padding-bottom: 25px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-25 {
  padding-top: 25px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-top-100 {
  padding-top: 100px;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-right-25 {
  padding-right: 25px;
}

.padding-right-50 {
  padding-right: 50px;
}

.padding-left-25 {
  padding-left: 25px;
}

.padding-left-50 {
  padding-left: 50px;
}

.padding-right-100 {
  padding-right: 100px;
}

.margin-0 {
  margin: 0px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

.margin-top-0 {
  margin-top: 0px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-25 {
  margin-left: 25px;
}

.full-width {
  width: 100%;
}

html,
body {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

.leaf-offer-name {
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #800202;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.ag-theme-alpine-dark {
  --ag-background-color: unset;
  --ag-header-background-color: unset;
  --ag-border-color: unset;
  --ag-odd-row-background-color: #202636;
  --ag-cell-horizontal-padding: 2px;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
  white-space: initial;
  text-overflow: ellipsis;
}

.ag-header {
  font-size: 15px;
  font-weight: bolder;
  border-bottom: 1px #393e46 solid;
}

.leaf-offer-avatar {
  display: flex;
  width: 2rem;
  height: 2rem;
  margin-left: 10px;
  background-color: #ffffff;
  text-align: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  overflow: hidden;
}
.leaf-offer-avatar img {
  object-fit: cover;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.action-button {
  color: #f6a23a;
}

#top-insights thead {
  display: none !important;
}

mat-form-field.mat-form-field .mat-input-element {
  color: #000000;
}
mat-form-field.mat-form-field .mat-form-field-label {
  color: #000000;
  font-weight: bold;
}
mat-form-field.mat-form-field .mat-form-field-underline {
  background-color: #171717;
}
mat-form-field.mat-form-field .mat-form-field-ripple {
  background-color: #171717;
}
mat-form-field.mat-form-field .mat-form-field-required-marker {
  color: #000000;
}
mat-form-field.mat-form-field .mat-focused .mat-form-field-label {
  color: #000000;
}
mat-form-field.mat-form-field .mat-focused .mat-form-field-ripple {
  background-color: #171717;
}
mat-form-field.mat-form-field .mat-focused .mat-form-field-required-marker {
  color: #000000;
}
mat-form-field.mat-form-field .mat-focused .mat-input-element {
  color: #000000;
}
mat-form-field.mat-form-field .mat-form-field-invalid .mat-input-element {
  color: #c96664;
}
mat-form-field.mat-form-field .mat-form-field-invalid .mat-form-field-label {
  color: #c96664;
}
mat-form-field.mat-form-field .mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #c96664;
}
mat-form-field.mat-form-field .mat-form-field-invalid .mat-form-field-ripple {
  background-color: #c96664;
}

.lv-dispensary-search-container {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
}
.lv-dispensary-search-container .lv-dispensary-search-box {
  width: 100%;
  padding-left: 8px;
  margin-bottom: 10px;
  height: 40px;
  border-radius: 8px;
  border: solid 2px #cbcbcb;
  background-color: #ffffff;
}
.lv-dispensary-search-container .lv-dispensary-search-box mat-icon {
  color: #8e8e93;
}
.lv-dispensary-search-container .lv-dispensary-search-box input {
  width: 80%;
  height: 80%;
  border: none;
  outline: transparent;
  appearance: none;
  font-size: 14px;
  color: #8e8e93;
  line-height: 16px;
}

.lv-product-search-container {
  min-width: calc(100% - 16px);
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
}
.lv-product-search-container .lv-product-search-box {
  width: 92%;
  padding-left: 8px;
  margin-bottom: 10px;
  height: 40px;
  border-radius: 8px;
  border: solid 2px #cbcbcb;
  background-color: #ffffff;
}
.lv-product-search-container .lv-product-search-box mat-icon {
  color: #8e8e93;
}
.lv-product-search-container .lv-product-search-box input {
  width: 80%;
  height: 80%;
  border: none;
  outline: transparent;
  appearance: none;
  font-size: 14px;
  color: #8e8e93;
  line-height: 16px;
}

.lv-icon-button:disabled {
  background-color: transparent !important;
}

.mat-raised-button.mat-review-round-button {
  min-width: 160px;
  max-width: 325px;
  height: 40px;
  border-radius: 24px;
  background-color: #800202;
  color: #ffffff !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 20px;
}

.mat-rounded-button {
  margin: 37px 0 16px !important;
  padding: 14px 133px 12px !important;
  border-radius: 24px !important;
  background-color: #800202;
  color: #ffffff !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-continue-round-button {
  min-width: 120px !important;
  height: 48px !important;
  border-radius: 24px !important;
  background-color: #800202;
  color: #ffffff !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

button.mat-button.mat-button-disabled.mat-continue-round-button-disabled {
  min-width: 120px !important;
  height: 48px !important;
  border-radius: 24px !important;
  background-color: #555659 !important;
  color: #8e8e93 !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-round-cancel-button {
  width: 78px;
  height: 40px;
  margin: 0 8px 0 0;
  padding: 10px 15px;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  border-radius: 20px !important;
  border: solid 2px #171717 !important;
}

.mat-round-back-button {
  height: 48px;
  margin: 0 8px 0 0;
  padding: 10px 15px;
  font-family: AvenirNext;
  font-size: 0.938rem;
  font-weight: 600;
  line-height: 1.33;
  text-align: center;
  border-radius: 24px !important;
  border: solid 2px #171717 !important;
}
.mat-round-back-button-disabled {
  background-color: #555659 !important;
  color: #8e8e93 !important;
}

.mat-activate-round-button {
  padding: 10px !important;
  border-radius: 24px !important;
  background-color: #026347;
  color: #ffffff !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-pause-round-button {
  padding: 10px !important;
  border-radius: 24px !important;
  background-color: #c96664;
  color: #ffffff !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-edit-round-button {
  padding: 10px !important;
  border-radius: 24px !important;
  background-color: #800202;
  color: #000000 !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-archive-round-button {
  padding: 10px !important;
  border-radius: 24px !important;
  background-color: #8e8e93;
  color: #ffffff !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-round-save-button {
  width: 69px;
  height: 40px;
  margin: 0 0 0 8px;
  padding: 10px 15px 8px 16px;
  border-radius: 24px !important;
  font-size: 17px;
  line-height: 22px;
  background-color: #800202;
  color: #ffffff !important;
}

.mat-round-save-button:disabled {
  background-color: #555659;
  color: #8e8e93;
}

.mat-round-more-button {
  min-width: 2.875rem !important;
  width: 2.875rem !important;
  height: 2.875rem !important;
  margin: 0 0.875rem 0.25rem !important;
  padding: 0.563rem !important;
  font-size: 17px !important;
  line-height: 22px !important;
  border-radius: 50% !important;
  background-color: #800202;
  color: #ffffff !important;
}

.mat-create-round-button {
  width: 132px !important;
  height: 48px !important;
  border-radius: 24px !important;
  background-color: #800202;
  color: #ffffff !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-add-round-button {
  min-width: 132px !important;
  height: 48px !important;
  border-radius: 24px !important;
  background-color: #800202;
  color: #ffffff !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-add-round-button:disabled {
  min-width: 132px !important;
  height: 48px !important;
  border-radius: 24px !important;
  background-color: #555659;
  color: #8e8e93;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-add-round-team {
  min-width: 132px !important;
  height: 40px !important;
  border-radius: 24px !important;
  background-color: #800202;
  color: #ffffff !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-add-round-team:disabled {
  min-width: 132px !important;
  height: 40px !important;
  border-radius: 24px !important;
  background-color: #555659;
  color: #8e8e93;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-create-add-button {
  margin-right: 10px !important;
}

.mat-illustration-button {
  width: 190px;
  height: 190px;
  border-radius: 16px !important;
  border: solid 4px #cbcbcb !important;
  white-space: pre-wrap !important;
}

.mat-illustration-button:hover {
  border: solid 4px #171717 !important;
}

.mat-illustration-button .png {
  max-width: 86px;
  max-height: 86px;
  height: auto;
}

.mat-illustration-button-text {
  line-height: 16px !important;
  padding-top: 10px !important;
}

.mat-icon-button.large {
  width: 48px;
  height: 48px;
  line-height: 48px;
}
.mat-icon-button.large .mat-icon {
  font-size: 48px;
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.mat-icon-button.medium {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.mat-icon-button.medium .mat-icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.mat-button.lv-answer-add-image-button {
  width: 99px;
  height: 24px;
  margin: 7.5px 0 1px 281px;
  padding: 4px 8px 3px 6px;
  border-radius: 4px;
  background-color: #800202;
  color: #ffffff;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: -0.08px;
}
.mat-button.lv-answer-add-image-button .mat-icon {
  margin-right: 2px;
  font-size: 16px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  color: #ffffff;
}

.mat-round-orange-button {
  font-size: 17px;
  font-weight: 550;
  color: #000000;
  border: solid 2px #800202 !important;
  background-color: #800202;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px !important;
}

.mat-round-white-opt-out-button {
  font-size: 17px;
  font-weight: 550;
  color: #ffffff;
  border: solid 2px #ffffff !important;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px !important;
}

.mat-round-white-button {
  font-size: 17px;
  font-weight: 550;
  color: #ffffff;
  padding: 5px 5px 4px 17px !important;
  border: solid 2px #ffffff !important;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px !important;
}

.mat-red-round-button {
  padding: 10px !important;
  border-radius: 24px !important;
  background-color: #c96664;
  color: #000000 !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-orange-round-button {
  padding: 10px !important;
  border-radius: 24px !important;
  background-color: #800202;
  color: #000000 !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-button-disabled {
  background-color: transparent !important;
  color: #9e9e9e !important;
}

.success-snackbar {
  color: #ffffff;
  background-color: #026347;
}

.success-snackbar .mat-simple-snackbar-action {
  color: #ffffff;
}

.error-snackbar {
  color: #ffffff;
  background-color: #c96664;
}

.error-snackbar .mat-simple-snackbar-action {
  color: #ffffff;
}

.lv-tab-active {
  color: #800202;
}

.mat-tab-label-active {
  color: #800202 !important;
  font-weight: bold !important;
}

.lv-tab-group {
  width: 100%;
  background: #171717;
  margin-bottom: 5px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
}
@media (max-width: 575px) {
  .lv-tab-group {
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (min-width: 576px) {
  .lv-tab-group {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (min-width: 768px) {
  .lv-tab-group {
    padding-left: 75px;
    padding-right: 75px;
  }
}
@media (min-width: 992px) {
  .lv-tab-group {
    padding-left: 200px;
    padding-right: 200px;
  }
}
@media (min-width: 1200px) {
  .lv-tab-group {
    padding-left: 256px;
    padding-right: 256px;
  }
}
.lv-tab-group div {
  margin-right: 20px;
  padding-bottom: 10px;
  cursor: pointer;
}
.lv-tab-group .lv-tab {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.lv-tab-group .lv-tab-active {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #800202;
  border-bottom: 2px solid;
}

.leaf-navbar {
  background-color: #171717;
}
.leaf-navbar.mat-toolbar-single-row {
  padding: 0 2.5rem;
  height: 3.5rem;
}
.leaf-navbar .leaf-navbar-logo-link {
  display: flex;
  align-items: center;
}

.lv-nav-button {
  color: #9e9e9e;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  padding: 0 10px;
}

.lv-nav-button-active {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
}

.leaf-toolbar-fill {
  flex: 1 1 auto;
}

.leaf-navbar-dropdown {
  background: #ffffff;
  color: #171717 !important;
}
.leaf-navbar-dropdown .mat-icon {
  color: #171717 !important;
}
.leaf-navbar-dropdown span {
  color: #171717 !important;
}

.leaf-brand-avatar {
  cursor: pointer;
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 25px;
  background-color: #ffffff;
  text-align: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.leaf-brand-avatar img {
  width: auto;
  height: auto;
  max-height: 35px;
  max-width: 35px;
  padding-top: 10px;
}

.offer-edit-nav-container {
  width: 100%;
  padding-top: 35px;
  place-content: stretch center;
  align-items: stretch;
  margin: 0 auto;
}
@media (max-width: 575px) {
  .offer-edit-nav-container {
    max-width: calc(100% - 10px);
  }
}
@media (min-width: 576px) {
  .offer-edit-nav-container {
    max-width: calc(100% - 50px);
  }
}
@media (min-width: 768px) {
  .offer-edit-nav-container {
    max-width: calc(100% - 150px);
  }
}
@media (min-width: 992px) {
  .offer-edit-nav-container {
    max-width: calc(100% - 200px);
  }
}
@media (min-width: 1200px) {
  .offer-edit-nav-container {
    max-width: 1000px;
  }
}

.offer-edit-nav-logo {
  width: 56px;
  height: 56px;
  margin: 0 8px 16px 0;
  object-fit: contain;
}

.offer-edit-close-icon {
  width: 56px;
  height: 56px;
}

.offer-edit-nav-column {
  width: 100%;
  max-width: 1025px;
}

.offer-edit-nav-title {
  margin: 7px;
  font-family: AvenirNext, sans-serif;
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.offer-edit-nav-description {
  margin: 7px;
  font-family: AvenirNext, sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.lb-avatar2 {
  display: block;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  text-align: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}
.lb-avatar2 img {
  max-height: 35px;
  max-width: 35px;
}

.bg-grey-table-row {
  background: #f5f5f5 !important;
}

/** Company table */
.lv-dispensary-table-container {
  max-height: 350px;
  overflow: auto;
  width: 100%;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.lv-dispensary-table-container::-webkit-scrollbar {
  display: none;
}

.lv-dispensary-table {
  width: 100%;
  background: #ffffff !important;
  color: #171717 !important;
}
.lv-dispensary-table .lv-header-text {
  font-size: 14px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lv-dispensary-table .lv-dispensary-table-select-button {
  color: #000000 !important;
  font-size: 14px;
  font-weight: bold;
  margin-left: 0;
  padding-left: 0;
}
.lv-dispensary-table :disabled {
  color: #8e8e93 !important;
}
.lv-dispensary-table .mat-checkbox {
  padding-right: 10px;
}
.lv-dispensary-table .mat-checkbox .mat-checkbox-frame {
  border-color: #800202;
  background-color: #ffffff;
}
.lv-dispensary-table .mat-table {
  background: #ffffff !important;
  color: #000000 !important;
}
.lv-dispensary-table .mat-cell,
.lv-dispensary-table .mat-footer-cell {
  color: #000000 !important;
}
.lv-dispensary-table .mat-header-cell {
  color: #000000 !important;
}
.lv-dispensary-table table th {
  background: #ffffff !important;
  color: #000000 !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}
.lv-dispensary-table table td {
  background: #ffffff !important;
  color: #000000 !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

.lv-dispensary-table-name {
  font-size: 16px;
  line-height: 21px;
}

.lv-dispensary-table-address {
  font-size: 14px;
  line-height: 16px;
  color: #8e8e93;
}

/** Generic table **/
.lv-table-container {
  margin-top: 25px;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.lv-table-container ::-webkit-scrollbar {
  display: none;
}
.lv-table-container table {
  width: 100%;
  max-height: 100%;
}
.lv-table-container table th {
  background: #171717;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}
@media (max-width: 575px) {
  .lv-table-container table {
    min-width: 95%;
  }
}
@media (min-width: 576px) {
  .lv-table-container table {
    min-width: 90%;
  }
}
@media (min-width: 768px) {
  .lv-table-container table {
    min-width: 80%;
  }
}
@media (min-width: 992px) {
  .lv-table-container table {
    min-width: 80%;
  }
}
@media (min-width: 1200px) {
  .lv-table-container table {
    min-width: 80%;
  }
}

.lv-result-table {
  margin-top: 25px;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.lv-result-table ::-webkit-scrollbar {
  display: none;
}

.lv-offer-table {
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
}
.lv-offer-table table {
  width: 100%;
  height: 100%;
}
.lv-offer-table table .mat-row {
  height: 70px;
}
.lv-offer-table table thead tr {
  background: #171717;
  height: 4rem;
  align-items: flex-end;
}
.lv-offer-table table tr {
  display: grid;
  grid-template-columns: 35% 1fr 1fr 1fr 15% 6.875rem 2.5rem;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  height: 3.5rem;
}
.lv-offer-table table th {
  background: #171717;
  color: #ffffff;
  font-size: 0.925rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  vertical-align: bottom;
  padding: 0 0.15625rem 0.688rem;
  box-sizing: border-box;
  text-align: center;
  border: 0;
}
.lv-offer-table table th .mat-sort-header-container {
  justify-content: center;
}
.lv-offer-table table th:first-child .mat-sort-header-container {
  justify-content: flex-start;
}
.lv-offer-table table th:last-child {
  padding-left: 0;
  padding-right: 1rem;
}
.lv-offer-table table td {
  color: #ffffff;
  font-size: 0.925rem;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  padding: 0 2.5px;
  border: 0;
}
.lv-offer-table table td .truncate {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lv-offer-table table td:first-child {
  text-align: left;
}
.lv-offer-table table td:last-child {
  padding-left: 0;
  padding-right: 1rem;
}
.lv-offer-table table td button {
  color: #800202;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width: 1300px) {
  .lv-offer-table table tr {
    grid-template-columns: 40% 1fr 1fr 1fr 15% 6.875rem 2.5rem;
  }
}
@media only screen and (min-width: 1400px) {
  .lv-offer-table table tr {
    grid-template-columns: 47% 1fr 1fr 1fr 15% 6.875rem 2.5rem;
  }
}

.lv-contest-table {
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
}
.lv-contest-table table {
  width: 100%;
  height: 100%;
}
.lv-contest-table table .mat-row {
  height: 70px;
}
.lv-contest-table table thead tr {
  background: #171717;
  height: 4rem;
  align-items: flex-end;
}
.lv-contest-table table tr {
  display: grid;
  grid-template-columns: 45% 1fr 1fr 1fr 1fr 2.5rem;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  height: 3.5rem;
}
.lv-contest-table table th {
  background: #171717;
  color: #ffffff;
  font-size: 0.925rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  vertical-align: bottom;
  padding: 0 0.15625rem 0.688rem;
  box-sizing: border-box;
  text-align: center;
  border: 0;
}
.lv-contest-table table th .mat-sort-header-container {
  justify-content: center;
}
.lv-contest-table table th:first-child .mat-sort-header-container {
  justify-content: flex-start;
}
.lv-contest-table table th:last-child {
  padding-left: 0;
  padding-right: 1rem;
}
.lv-contest-table table td {
  color: #ffffff;
  font-size: 0.925rem;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  padding: 0 2.5px;
  border: 0;
}
.lv-contest-table table td:first-child {
  text-align: left;
}
.lv-contest-table table td:last-child {
  padding-left: 0;
  padding-right: 1rem;
}
.lv-contest-table table td button {
  color: #800202;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-tooltip.table-tooltip {
  color: #000000;
  background-color: #ffffff;
  font-size: 1rem;
  line-height: 1.2;
  padding: 1rem;
  text-transform: capitalize;
}

.leaf-offer-name {
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 76px);
}

@media (min-width: 576px) {
  .hide-column-sm {
    visibility: hidden !important;
  }
}

.lv-results-favorite-table {
  margin-top: 25px;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
}
.lv-results-favorite-table .lv-result-response {
  width: 22rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #ffffff;
  padding: 10px 0;
}
.lv-results-favorite-table .lv-result-dispensary {
  width: 6rem;
}
.lv-results-favorite-table table {
  width: 100%;
  color: #ffffff;
}
.lv-results-favorite-table table th {
  background: #171717;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
}
.lv-results-favorite-table table td {
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: normal;
}

.lv-products-list-container {
  width: 404px;
  height: 500px;
  border-radius: 16px;
  border: solid 2px #cbcbcb;
  background-color: #ffffff;
  padding-top: 1rem;
  overflow: auto;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

.lv-products-table-container {
  max-height: 350px;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.lv-products-table-container::-webkit-scrollbar {
  display: none;
}

.lv-products-table {
  width: 100%;
  background: #ffffff !important;
  color: #171717 !important;
}
.lv-products-table .lv-header-text {
  font-size: 14px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lv-products-table .lv-products-table-select-button {
  color: #000000 !important;
  font-size: 14px;
  font-weight: bold;
  margin-left: 0;
  padding-left: 0;
}
.lv-products-table :disabled {
  color: #8e8e93 !important;
}
.lv-products-table .mat-checkbox {
  padding-right: 10px;
}
.lv-products-table .mat-checkbox .mat-checkbox-frame {
  border-color: #800202;
  background-color: #ffffff;
}
.lv-products-table .mat-table {
  background: #ffffff !important;
  color: #000000 !important;
}
.lv-products-table .mat-cell,
.lv-products-table .mat-footer-cell {
  color: #000000 !important;
  height: 4rem;
}
.lv-products-table .mat-header-row {
  height: 28px !important;
}
.lv-products-table .mat-header-cell {
  color: #000000 !important;
}
.lv-products-table table th {
  background: #ffffff !important;
  color: #000000 !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}
.lv-products-table table td {
  background: #ffffff !important;
  color: #000000 !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}
.lv-products-table .lv-trash-column {
  padding-right: 0 !important;
  padding-left: 5rem !important;
  text-align: right !important;
}

.lv-products-table-name {
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;
}

.lv-products-table-subtitle {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-transform: capitalize;
}

.lv-products-table-in-stock {
  font-size: 14px;
  line-height: 17px;
  color: #8e8e93;
}

.lv-product-table-container {
  margin-top: 0.5rem;
  max-height: 325px;
  overflow: auto;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: 0 !important;
  /* Firefox */
}

.lv-product-table-container::-webkit-scrollbar {
  display: none !important;
}

.lv-product-table-selection-container {
  margin-top: 0.5rem;
  max-height: 450px;
  overflow: auto;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: 0 !important;
  /* Firefox */
}

.lv-product-table-selection-container-table-container::-webkit-scrollbar {
  display: none !important;
}

.lv-product-table {
  width: 100%;
  background: #ffffff !important;
  color: #171717 !important;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: 0;
  /* Firefox */
}
.lv-product-table .lv-header-text {
  font-size: 14px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lv-product-table .lv-product-table-select-button {
  color: #000000 !important;
  font-size: 14px;
  font-weight: bold;
  margin-left: 0;
  padding-left: 0;
}
.lv-product-table :disabled {
  color: #8e8e93 !important;
}
.lv-product-table .mat-checkbox {
  padding-right: 10px;
}
.lv-product-table .mat-checkbox .mat-checkbox-frame {
  border-color: #800202;
  background-color: #ffffff;
}
.lv-product-table .mat-table {
  background: #ffffff !important;
  color: #000000 !important;
}
.lv-product-table .mat-cell,
.lv-product-table .mat-footer-cell {
  color: #000000 !important;
}
.lv-product-table .mat-header-cell {
  color: #000000 !important;
}
.lv-product-table table th {
  background: #ffffff !important;
  color: #000000 !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}
.lv-product-table table td {
  background: #ffffff !important;
  color: #000000 !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

.bg-grey-table-row {
  background: #f5f5f5 !important;
}

.lv-dispensary-table-container {
  max-height: 350px;
  overflow: auto;
  width: 100%;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.lv-dispensary-table-container::-webkit-scrollbar {
  display: none;
}

.lv-dispensary-table {
  width: 100%;
  background: #ffffff !important;
  color: #171717 !important;
}
.lv-dispensary-table .lv-header-text {
  font-size: 14px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lv-dispensary-table .lv-dispensary-table-select-button {
  color: #000000 !important;
  font-size: 14px;
  font-weight: bold;
  margin-left: 0;
  padding-left: 0;
}
.lv-dispensary-table :disabled {
  color: #8e8e93 !important;
}
.lv-dispensary-table .lv-dispensary-table-disabled-row {
  opacity: 0.3;
  cursor: default;
}
.lv-dispensary-table .mat-checkbox {
  padding-right: 10px;
}
.lv-dispensary-table .mat-checkbox .mat-checkbox-frame {
  border-color: #800202;
  background-color: #ffffff;
}
.lv-dispensary-table .mat-table {
  background: #ffffff !important;
  color: #000000 !important;
}
.lv-dispensary-table .mat-cell,
.lv-dispensary-table .mat-footer-cell {
  color: #000000 !important;
}
.lv-dispensary-table .mat-header-cell {
  color: #000000 !important;
}
.lv-dispensary-table th.mat-header-cell:first-of-type,
.lv-dispensary-table td.mat-cell:first-of-type,
.lv-dispensary-table td.mat-footer-cell:first-of-type {
  padding-left: 1rem;
}
.lv-dispensary-table th.mat-header-cell:last-of-type,
.lv-dispensary-table td.mat-cell:last-of-type,
.lv-dispensary-table td.mat-footer-cell:last-of-type {
  padding-right: 1rem;
}

.lv-dispensary-table-name {
  font-size: 16px;
  line-height: 21px;
}

.lv-dispensary-table-address {
  font-size: 14px;
  line-height: 16px;
  color: #8e8e93;
}

.lv-table-container {
  margin-top: 25px;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.lv-table-container ::-webkit-scrollbar {
  display: none;
}
.lv-table-container table {
  width: 100%;
  max-height: 100%;
}
.lv-table-container table th {
  background: #171717;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}
@media (max-width: 575px) {
  .lv-table-container table {
    min-width: 95%;
  }
}
@media (min-width: 576px) {
  .lv-table-container table {
    min-width: 90%;
  }
}
@media (min-width: 768px) {
  .lv-table-container table {
    min-width: 80%;
  }
}
@media (min-width: 992px) {
  .lv-table-container table {
    min-width: 80%;
  }
}
@media (min-width: 1200px) {
  .lv-table-container table {
    min-width: 80%;
  }
}

.lv-product-table-container {
  margin-top: 0.5rem;
  max-height: 325px;
  overflow: auto;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: 0 !important;
  /* Firefox */
}

.lv-product-table-container::-webkit-scrollbar {
  display: none !important;
}

.lv-product-table-selection-container {
  margin-top: 0.5rem;
  max-height: 450px;
  overflow: auto;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: 0 !important;
  /* Firefox */
}

.lv-product-table-selection-container-table-container::-webkit-scrollbar {
  display: none !important;
}

.lv-product-table {
  width: 100%;
  background: #ffffff !important;
  color: #171717 !important;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: 0;
  /* Firefox */
}
.lv-product-table .lv-header-text {
  font-size: 14px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lv-product-table .lv-product-table-select-button {
  color: #000000 !important;
  font-size: 14px;
  font-weight: bold;
  margin-left: 0;
  padding-left: 0;
}
.lv-product-table :disabled {
  color: #8e8e93 !important;
}
.lv-product-table .mat-checkbox {
  padding-right: 10px;
}
.lv-product-table .mat-checkbox .mat-checkbox-frame {
  border-color: #800202;
  background-color: #ffffff;
}
.lv-product-table .mat-table {
  background: #ffffff !important;
  color: #000000 !important;
}
.lv-product-table .mat-cell,
.lv-product-table .mat-footer-cell {
  color: #000000 !important;
}
.lv-product-table .mat-header-cell {
  color: #000000 !important;
}
.lv-product-table table th {
  background: #ffffff !important;
  color: #000000 !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}
.lv-product-table table td {
  background: #ffffff !important;
  color: #000000 !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}
.lv-product-table .mat-column-select:first-of-type {
  padding-left: 15px;
}

/** Employee Table  */
.lv-employee-table-container {
  height: 19.625rem;
  flex-shrink: 1;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.lv-employee-table-container::-webkit-scrollbar {
  display: none;
}

.lv-employee-table {
  width: 100%;
  background: #ffffff !important;
  color: #171717 !important;
}
.lv-employee-table th.mat-header-cell:first-of-type,
.lv-employee-table td.mat-cell:first-of-type,
.lv-employee-table td.mat-footer-cell:first-of-type {
  padding-left: 1rem;
}
.lv-employee-table th.mat-header-cell:last-of-type,
.lv-employee-table td.mat-cell:last-of-type,
.lv-employee-table td.mat-footer-cell:last-of-type {
  padding-right: 1rem;
}
.lv-employee-table .lv-header-text {
  font-size: 14px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lv-employee-table .lv-employee-table-select-button {
  color: #000000 !important;
  font-size: 14px;
  font-weight: bold;
  margin-left: 0;
  padding-left: 0;
}
.lv-employee-table .lv-employee-table-check-button {
  width: 1rem;
  padding-left: 1rem !important;
}
.lv-employee-table .lv-employee-table-remove-item {
  font-size: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
}
.lv-employee-table :disabled {
  color: #8e8e93 !important;
}
.lv-employee-table .mat-checkbox {
  padding-right: 10px;
}
.lv-employee-table .mat-checkbox .mat-checkbox-frame {
  border-color: #800202;
  background-color: #ffffff;
}
.lv-employee-table .mat-table {
  background: #ffffff !important;
  color: #000000 !important;
}
.lv-employee-table .mat-cell,
.lv-employee-table .mat-footer-cell {
  color: #000000 !important;
}
.lv-employee-table .mat-header-cell {
  color: #000000 !important;
}
.lv-employee-table table th {
  background: #ffffff !important;
  color: #000000 !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}
.lv-employee-table table td {
  background: #ffffff !important;
  color: #000000 !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}
.lv-employee-table .lv-employee-td-check {
  width: 1rem;
  padding-left: 1rem !important;
}

.lv-employee-table-name {
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;
}

.lv-employee-table-role {
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;
  font-weight: bold;
}

.lv-employee-table-address {
  font-size: 14px;
  line-height: 16px;
  color: #8e8e93;
}

.lv-employee-table-role-type {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  color: #8e8e93;
  text-align: right;
  text-transform: capitalize;
}

.lv-employee-role-column {
  padding-right: 0 !important;
  text-align: right !important;
}

.lv-result-table {
  margin-top: 25px;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.lv-result-table ::-webkit-scrollbar {
  display: none;
}

.lv-offer-table {
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
}
.lv-offer-table table {
  width: 100%;
  height: 100%;
}
.lv-offer-table table .mat-row {
  height: 70px;
}
.lv-offer-table table thead tr {
  background: #171717;
  height: 4rem;
  align-items: flex-end;
}
.lv-offer-table table tr {
  display: grid;
  grid-template-columns: 35% 1fr 1fr 1fr 15% 6.875rem 2.5rem;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  height: 3.5rem;
}
.lv-offer-table table th {
  background: #171717;
  color: #ffffff;
  font-size: 0.925rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  vertical-align: bottom;
  padding: 0 0.15625rem 0.688rem;
  box-sizing: border-box;
  text-align: center;
  border: 0;
}
.lv-offer-table table th .mat-sort-header-container {
  justify-content: center;
}
.lv-offer-table table th:first-child .mat-sort-header-container {
  justify-content: flex-start;
}
.lv-offer-table table th:last-child {
  padding-left: 0;
  padding-right: 1rem;
}
.lv-offer-table table td {
  color: #ffffff;
  font-size: 0.925rem;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  padding: 0 2.5px;
  border: 0;
}
.lv-offer-table table td .truncate {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lv-offer-table table td:first-child {
  text-align: left;
}
.lv-offer-table table td:last-child {
  padding-left: 0;
  padding-right: 1rem;
}
.lv-offer-table table td button {
  color: #800202;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width: 1300px) {
  .lv-offer-table table tr {
    grid-template-columns: 40% 1fr 1fr 1fr 15% 6.875rem 2.5rem;
  }
}
@media only screen and (min-width: 1400px) {
  .lv-offer-table table tr {
    grid-template-columns: 47% 1fr 1fr 1fr 15% 6.875rem 2.5rem;
  }
}

.mat-tooltip.table-tooltip {
  color: #000000;
  background-color: #ffffff;
  font-size: 1rem;
  line-height: 1.2;
  padding: 1rem;
  text-transform: capitalize;
}

.leaf-offer-name {
  cursor: pointer;
  font-size: 0.925rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

@media (min-width: 576px) {
  .hide-column-sm {
    visibility: hidden !important;
  }
}

.lv-results-favorite-table {
  margin-top: 25px;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
}
.lv-results-favorite-table .lv-result-response {
  width: 22rem;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #ffffff;
  padding: 10px 0;
}
.lv-results-favorite-table .lv-result-dispensary {
  width: 6rem;
}
.lv-results-favorite-table table {
  width: 100%;
  color: #ffffff;
}
.lv-results-favorite-table table th {
  background: #171717;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
}
.lv-results-favorite-table table td {
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: normal;
}

.lv-products-list-container {
  width: 404px;
  height: 500px;
  border-radius: 16px;
  border: solid 2px #cbcbcb;
  background-color: #ffffff;
  padding-top: 1rem;
  overflow: auto;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

.lv-products-table-container {
  max-height: 350px;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.lv-products-table-container::-webkit-scrollbar {
  display: none;
}

.lv-products-table {
  width: 100%;
  background: #ffffff !important;
  color: #171717 !important;
}
.lv-products-table .lv-header-text {
  font-size: 14px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lv-products-table .lv-products-table-select-button {
  color: #000000 !important;
  font-size: 14px;
  font-weight: bold;
  margin-left: 0;
  padding-left: 0;
}
.lv-products-table :disabled {
  color: #8e8e93 !important;
}
.lv-products-table .mat-checkbox {
  padding-right: 10px;
}
.lv-products-table .mat-checkbox .mat-checkbox-frame {
  border-color: #800202;
  background-color: #ffffff;
}
.lv-products-table .mat-table {
  background: #ffffff !important;
  color: #000000 !important;
}
.lv-products-table .mat-cell,
.lv-products-table .mat-footer-cell {
  color: #000000 !important;
  height: 4rem;
}
.lv-products-table .mat-header-row {
  height: 28px !important;
}
.lv-products-table .mat-header-cell {
  color: #000000 !important;
}
.lv-products-table table th {
  background: #ffffff !important;
  color: #000000 !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}
.lv-products-table table td {
  background: #ffffff !important;
  color: #000000 !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}
.lv-products-table .lv-trash-column {
  padding-right: 0 !important;
  padding-left: 5rem !important;
  text-align: right !important;
}

.lv-products-table-name {
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;
}

.lv-products-table-subtitle {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-transform: capitalize;
}

.lv-products-table-in-stock {
  font-size: 14px;
  line-height: 17px;
  color: #8e8e93;
}

.lv-light-dialog mat-dialog-container {
  background: #ffffff !important;
  color: #171717 !important;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-light-dialog mat-dialog-container ::-webkit-scrollbar {
  display: none !important;
}
.lv-light-dialog mat-dialog-container .mat-dialog-content {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-light-dialog mat-dialog-container .mat-dialog-content ::-webkit-scrollbar {
  display: none !important;
}

.lv-fullscreen-dialog .mat-dialog-container {
  border-radius: 0;
  padding: 0;
}

.lv-light-dialog-rounded mat-dialog-container {
  background: #ffffff !important;
  color: #171717 !important;
  border-radius: 0.75rem !important;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-light-dialog-rounded mat-dialog-container ::-webkit-scrollbar {
  display: none !important;
}
.lv-light-dialog-rounded mat-dialog-container .mat-dialog-content {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-light-dialog-rounded mat-dialog-container .mat-dialog-content ::-webkit-scrollbar {
  display: none !important;
}

.lv-light-dialog-roundest mat-dialog-container {
  background: #ffffff !important;
  color: #171717 !important;
  border-radius: 18.4px !important;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-light-dialog-roundest mat-dialog-container ::-webkit-scrollbar {
  display: none !important;
}
.lv-light-dialog-roundest mat-dialog-container .mat-dialog-content {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-light-dialog-roundest mat-dialog-container .mat-dialog-content ::-webkit-scrollbar {
  display: none !important;
}

.lv-transparent-dialog-rounded mat-dialog-container {
  background: transparent;
  color: #ffffff;
  border-radius: 18px;
  padding: 0;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-transparent-dialog-rounded mat-dialog-container ::-webkit-scrollbar {
  display: none !important;
}
.lv-transparent-dialog-rounded mat-dialog-container .mat-dialog-content {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-transparent-dialog-rounded mat-dialog-container .mat-dialog-content ::-webkit-scrollbar {
  display: none !important;
}

.lv-dark-dialog mat-dialog-container {
  background: #171717 !important;
  color: #ffffff !important;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-dark-dialog mat-dialog-container ::-webkit-scrollbar {
  display: none !important;
}
.lv-dark-dialog mat-dialog-container .mat-dialog-content {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-dark-dialog mat-dialog-container .mat-dialog-content ::-webkit-scrollbar {
  display: none !important;
}

.mat-dialog-content {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.mat-dialog-content ::-webkit-scrollbar {
  display: none !important;
}

@media (max-width: 575px) {
  .lv-dialog-wrapper {
    padding-left: 10px;
    padding-right: 10px;
    height: 125px;
  }
}
@media (min-width: 576px) {
  .lv-dialog-wrapper {
    padding-left: 25px;
    padding-right: 25px;
    height: 125px;
  }
}
@media (min-width: 768px) {
  .lv-dialog-wrapper {
    padding-left: 75px;
    padding-right: 75px;
    height: 150px;
  }
}
@media (min-width: 992px) {
  .lv-dialog-wrapper {
    padding-left: 200px;
    padding-right: 200px;
    height: 175px;
  }
}
@media (min-width: 1200px) {
  .lv-dialog-wrapper {
    padding-left: 256px;
    padding-right: 256px;
    height: 200px;
  }
}

.lv-dialog-title {
  font-size: 35px;
  font-weight: bold;
  color: #ffffff;
}
.lv-dialog-title mat-icon {
  color: #ffffff;
  font-size: 56px;
  height: 56px;
  width: 56px;
}

.lv-offer-type-title-container {
  margin-bottom: 16px;
}

.lv-offer-type-title {
  font-size: 26px !important;
  font-weight: bold !important;
}

.lv-offer-type-description {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
}

.lv-offer-type-square {
  margin-top: 30px !important;
}

.lv-dispensary-title-container {
  margin-bottom: 16px;
}

.lv-dispensary-title {
  font-size: 26px !important;
  font-weight: bold !important;
}

.lv-dispensary-title-description {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 25px;
}

.lv-product-list-container {
  width: 404px;
  height: 454px;
  border-radius: 16px;
  border: solid 2px #cbcbcb;
  background-color: #ffffff;
  overflow: auto;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

.lv-product-list-title {
  margin-top: 0.5rem;
  max-width: 100%;
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.lv-dispensary-list-items {
  height: 100%;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

.lv-dispensary-list-items::-webkit-scrollbar {
  display: none;
}

.lv-dispensary-list-add-buttons {
  width: 100%;
}

.lv-dispensary-list-buttons {
  max-width: 1600px;
  margin-top: 30px;
  padding-right: 26px;
}

.lv-dispensary-list-title {
  width: 100%;
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.438rem;
  padding-bottom: 0.5rem;
  height: 3.125rem;
}

.lv-dispensary-list-item {
  width: 100%;
  height: 40px;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}

.lv-dispensary-item-selected {
  background-color: #fbd19c;
  font-weight: bold;
  width: 100%;
}

.lv-dialog-result-title {
  font-size: 28px;
  font-weight: bold;
  line-height: 37px;
}

.lv-dialog-result-subtitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
}

.lv-dialog-result-chart-row {
  width: 90%;
  height: 275px;
  margin-left: auto;
  margin-right: auto;
}

.lv-dialog-result-matrix-row {
  width: 90%;
  height: 275px;
}
.lv-dialog-result-matrix-row .lv-matrix-result-answer-label {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  width: 240px;
  padding-right: 10px;
}

.lv-dialog-rating-section {
  padding-bottom: 30px;
}
.lv-dialog-rating-section .lv-rating-average-number {
  font-size: 68px;
  font-weight: bold;
  line-height: 88px;
}
.lv-dialog-rating-section .lv-rating-average-text {
  font-size: 21px;
  font-weight: bold;
  line-height: 27px;
}
.lv-dialog-rating-section .lv-rating-star-row {
  width: 100%;
  max-width: 160px;
}
.lv-dialog-rating-section .lv-rating-star-row mat-icon {
  font-size: 27px;
  height: 27px;
  width: 27px;
}

.lv-dialog-result-pinned-title {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 15px;
}

.lv-dialog-result-pinned-answer {
  width: 250px;
  height: 290px;
  padding: 10px;
  padding-left: 50px;
}

.lv-dialog-result-upload-box {
  margin: 5px;
  width: auto;
  height: 140px;
  border-radius: 16px;
}
.lv-dialog-result-upload-box img {
  border-radius: 16px;
  width: auto;
  height: 140px;
}
.lv-dialog-result-upload-box video {
  border-radius: 16px;
  width: auto;
  height: 140px;
}

.lv-dialog-result-search .lv-filter-results-focus {
  color: #000000 !important;
  border: 1px solid #000000 !important;
}
.lv-dialog-result-search .lv-filter-results-focus mat-icon {
  color: #000000 !important;
}
.lv-dialog-result-search .lv-filter-results {
  padding: 0.75rem;
  color: #cbcbcb !important;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #cbcbcb;
  width: auto;
}
.lv-dialog-result-search .lv-filter-results input[type=text] {
  color: #cbcbcb;
  background: #ffffff;
  border: none;
  outline: none;
  font-size: 17px;
  width: 80%;
}
.lv-dialog-result-search .lv-filter-results ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cbcbcb;
  opacity: 1;
  /* Firefox */
}
.lv-dialog-result-search .lv-filter-results ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cbcbcb;
}
.lv-dialog-result-search .lv-filter-results input[type=text]:focus {
  outline: none;
  color: #000000;
  border: none;
}
.lv-dialog-result-search .lv-filter-results input[type=text]:disabled {
  outline: none;
  color: #555659;
  border: none;
}
.lv-dialog-result-search .lv-search-empty {
  margin-top: 150px;
}
.lv-dialog-result-search .lv-search-empty-title {
  font-size: 1.313rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.688rem;
  color: #000000;
}
.lv-dialog-result-search .lv-search-empty-subtitle {
  font-size: 1.125rem;
  font-weight: normal;
  text-align: center;
  line-height: 1.5rem;
  color: #000000;
}

.lv-search-response {
  text-wrap: normal;
  width: 250px;
  height: auto;
}

.lv-email-form {
  width: 100%;
}

.lv-add-team-form {
  width: 100%;
  margin: 20px;
}
.lv-add-team-form .mat-form-field {
  min-width: 80% !important;
}

.lv-dispensary-stat-card {
  background: #f5f5f5;
  border-radius: 16px;
  width: 50%;
  max-height: 109px;
  padding: 15px;
}

.lv-dispensary-stat-value-container {
  color: #000000;
  font-size: 14px;
  line-height: 18px;
}
.lv-dispensary-stat-value-container .mat-icon {
  height: 24px;
  width: 24px;
  line-height: 1.5;
  position: relative;
  top: 5px;
}

.lv-dispensary-stat-title {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  line-height: 26px;
  padding-bottom: 10px;
}

.lv-dispensary-stat-value {
  color: #000000;
  font-size: 50px;
  line-height: 65px;
  font-weight: bold;
}

.lv-dispensary-stat-icon {
  width: 32px !important;
  height: 32px !important;
}

.lv-light-dialog mat-dialog-container {
  background: #ffffff !important;
  color: #171717 !important;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-light-dialog mat-dialog-container ::-webkit-scrollbar {
  display: none !important;
}
.lv-light-dialog mat-dialog-container .mat-dialog-content {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-light-dialog mat-dialog-container .mat-dialog-content ::-webkit-scrollbar {
  display: none !important;
}

.lv-light-dialog-rounded mat-dialog-container {
  background: #ffffff !important;
  color: #171717 !important;
  border-radius: 0.75rem !important;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-light-dialog-rounded mat-dialog-container ::-webkit-scrollbar {
  display: none !important;
}
.lv-light-dialog-rounded mat-dialog-container .mat-dialog-content {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-light-dialog-rounded mat-dialog-container .mat-dialog-content ::-webkit-scrollbar {
  display: none !important;
}

.lv-transparent-dialog-rounded mat-dialog-container {
  background: transparent;
  color: #ffffff;
  border-radius: 18px;
  padding: 0;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-transparent-dialog-rounded mat-dialog-container ::-webkit-scrollbar {
  display: none !important;
}
.lv-transparent-dialog-rounded mat-dialog-container .mat-dialog-content {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-transparent-dialog-rounded mat-dialog-container .mat-dialog-content ::-webkit-scrollbar {
  display: none !important;
}

.lv-dark-dialog mat-dialog-container {
  background: #171717 !important;
  color: #ffffff !important;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-dark-dialog mat-dialog-container ::-webkit-scrollbar {
  display: none !important;
}
.lv-dark-dialog mat-dialog-container .mat-dialog-content {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-dark-dialog mat-dialog-container .mat-dialog-content ::-webkit-scrollbar {
  display: none !important;
}

.mat-dialog-content {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.mat-dialog-content ::-webkit-scrollbar {
  display: none !important;
}

@media (max-width: 575px) {
  .lv-dialog-wrapper {
    padding-left: 10px;
    padding-right: 10px;
    height: 125px;
  }
}
@media (min-width: 576px) {
  .lv-dialog-wrapper {
    padding-left: 25px;
    padding-right: 25px;
    height: 125px;
  }
}
@media (min-width: 768px) {
  .lv-dialog-wrapper {
    padding-left: 75px;
    padding-right: 75px;
    height: 150px;
  }
}
@media (min-width: 992px) {
  .lv-dialog-wrapper {
    padding-left: 200px;
    padding-right: 200px;
    height: 175px;
  }
}
@media (min-width: 1200px) {
  .lv-dialog-wrapper {
    padding-left: 256px;
    padding-right: 256px;
    height: 200px;
  }
}

.lv-dialog-title {
  font-size: 35px;
  font-weight: bold;
  color: #ffffff;
}
.lv-dialog-title mat-icon {
  color: #ffffff;
  font-size: 56px;
  height: 56px;
  width: 56px;
}

.lv-offer-type-title-container {
  margin-bottom: 16px;
}

.lv-offer-type-title {
  font-size: 26px !important;
  font-weight: bold !important;
}

.lv-offer-type-description {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
}

.lv-offer-type-square {
  margin-top: 30px !important;
}

.lv-dispensary-title-container.mat-dialog-title {
  margin-bottom: 0;
}

.lv-dispensary-title {
  font-size: 26px !important;
  font-weight: bold !important;
}

.lv-dispensary-title-description {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 3rem;
}

.lv-dispensary-list-container {
  width: 404px;
  height: 454px;
  border-radius: 16px;
  border: solid 2px #cbcbcb;
  background-color: #ffffff;
  overflow: auto;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

.lv-dispensary-list-container-inner {
  height: 100%;
  width: 100%;
  max-height: 425px;
  overflow: auto;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

.lv-dispensary-list-items {
  height: 100%;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

.lv-dispensary-list-items::-webkit-scrollbar {
  display: none !important;
}

.lv-dispensary-list-add-buttons {
  width: 100%;
}

.lv-dispensary-list-buttons {
  max-width: 1600px;
  margin-top: 30px;
  padding-right: 26px;
}

.lv-dispensary-selection-list-buttons {
  max-width: 1600px;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-right: 25rem;
}

.lv-dispensary-list-title {
  width: 100%;
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.438rem;
  padding-bottom: 0.5rem;
  height: 3.125rem;
}

.lv-dispensary-list-item {
  width: 100%;
  height: 40px;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}

.lv-dispensary-item-selected {
  background-color: #fbd19c;
  font-weight: bold;
  width: 100%;
}

.lv-product-list-container {
  width: 404px;
  height: 454px;
  border-radius: 16px;
  border: solid 2px #cbcbcb;
  background-color: #ffffff;
  overflow: auto;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

.lv-product-list-title {
  margin-top: 0.5rem;
  max-width: 100%;
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.lv-employee-list-title {
  width: 100%;
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;
  padding: 10px 16px;
  height: 3.125rem;
}

.lv-employee-select-buttons {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding: 0.563rem 0.437rem 0.563rem 1rem;
  border-top: 1px solid #cbcbcb;
  height: 3.125rem;
}
.lv-employee-select-buttons .mat-button {
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  padding: 0.5rem 0.5rem 0.375rem;
  height: 2rem;
  min-width: 2.813rem;
}

.lv-dialog-result-title {
  font-size: 28px;
  font-weight: bold;
  line-height: 37px;
}

.lv-dialog-result-subtitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
}

.lv-dialog-result-chart-row {
  width: 90%;
  height: 275px;
  margin-left: auto;
  margin-right: auto;
}

.lv-dialog-result-matrix-row {
  width: 90%;
}
.lv-dialog-result-matrix-row .lv-matrix-result-answer-label {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  width: 240px;
  padding-right: 10px;
}

.lv-dialog-rating-section {
  padding-bottom: 30px;
}
.lv-dialog-rating-section .lv-rating-average-number {
  font-size: 68px;
  font-weight: bold;
  line-height: 88px;
}
.lv-dialog-rating-section .lv-rating-average-text {
  font-size: 21px;
  font-weight: bold;
  line-height: 27px;
}
.lv-dialog-rating-section .lv-rating-star-row {
  width: 100%;
  max-width: 160px;
}
.lv-dialog-rating-section .lv-rating-star-row mat-icon {
  font-size: 27px;
  height: 27px;
  width: 27px;
}

.lv-dialog-result-pinned-title {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 15px;
}

.lv-dialog-result-pinned-answer {
  width: 250px;
  height: 290px;
  padding: 10px;
  padding-left: 50px;
}

.lv-dialog-result-upload-box {
  margin: 5px;
  width: auto;
  height: 140px;
  border-radius: 16px;
}
.lv-dialog-result-upload-box img {
  border-radius: 16px;
  width: auto;
  height: 140px;
}
.lv-dialog-result-upload-box video {
  border-radius: 16px;
  width: auto;
  height: 140px;
}

.lv-dialog-result-search .lv-filter-results-focus {
  color: #000000 !important;
  border: 1px solid #000000 !important;
}
.lv-dialog-result-search .lv-filter-results-focus mat-icon {
  color: #000000 !important;
}
.lv-dialog-result-search .lv-filter-results {
  padding: 0.75rem;
  color: #cbcbcb !important;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #cbcbcb;
  width: auto;
}
.lv-dialog-result-search .lv-filter-results input[type=text] {
  color: #cbcbcb;
  background: #ffffff;
  border: none;
  outline: none;
  font-size: 17px;
  width: 80%;
}
.lv-dialog-result-search .lv-filter-results ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cbcbcb;
  opacity: 1;
  /* Firefox */
}
.lv-dialog-result-search .lv-filter-results ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cbcbcb;
}
.lv-dialog-result-search .lv-filter-results input[type=text]:focus {
  outline: none;
  color: #000000;
  border: none;
}
.lv-dialog-result-search .lv-filter-results input[type=text]:disabled {
  outline: none;
  color: #555659;
  border: none;
}
.lv-dialog-result-search .lv-search-empty {
  margin-top: 150px;
}
.lv-dialog-result-search .lv-search-empty-title {
  font-size: 1.313rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.688rem;
  color: #000000;
}
.lv-dialog-result-search .lv-search-empty-subtitle {
  font-size: 1.125rem;
  font-weight: normal;
  text-align: center;
  line-height: 1.5rem;
  color: #000000;
}

.lv-search-response {
  text-wrap: normal;
  width: 250px;
  height: auto;
}

.lv-email-form {
  width: 100%;
}

.lv-add-team-form {
  width: 100%;
  margin: 20px;
}
.lv-add-team-form .mat-form-field {
  min-width: 80% !important;
}

.lv-add-member-form {
  width: 100%;
  margin-right: 20px;
}
.lv-add-member-form .mat-form-field {
  min-width: 80% !important;
}

.lv-employee-empty-state {
  width: 100%;
  font-size: 1rem;
  line-height: 27px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  box-sizing: border-box;
  margin: auto;
}

/** Whisper Blast from results dialog */
.lv-filter-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  width: 100%;
}

.lv-question-label-blast {
  font-size: 1.063rem;
  font-weight: bold;
  line-height: 1.375rem;
  color: #000000;
  padding-top: 0.5rem;
  padding-left: 16px;
  padding-right: 16px;
}

.lv-question-rating-filter {
  padding-top: 0.5rem;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 1.063rem;
  font-weight: bold;
  line-height: 1.375rem;
  margin-left: auto;
  margin-right: auto;
}

.lv-question-radio-container {
  padding-top: 0.5rem;
  padding-left: 16px;
  padding-right: 16px;
}

.lv-question-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}

.lv-question-radio-button {
  margin: 5px;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.lv-dispensary-stat-card {
  background: #f5f5f5;
  border-radius: 16px;
  width: 50%;
  max-height: 109px;
  padding: 15px;
}

.lv-dispensary-stat-value-container {
  color: #000000;
  font-size: 14px;
  line-height: 18px;
}
.lv-dispensary-stat-value-container .mat-icon {
  height: 24px;
  width: 24px;
  line-height: 1.5;
  position: relative;
  top: 5px;
}

.lv-dispensary-stat-title {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  line-height: 26px;
  padding-bottom: 10px;
}

.lv-dispensary-stat-value {
  color: #000000;
  font-size: 50px;
  line-height: 65px;
  font-weight: bold;
}

.lv-dispensary-stat-icon {
  width: 32px !important;
  height: 32px !important;
}

.lv-filter-button.mat-icon-button {
  width: 36px;
  height: 36px;
  line-height: 36px;
}
.lv-filter-button.mat-icon-button .lv-filter-icon.mat-icon {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 28px;
}

.lv-filter-menu.mat-menu-panel {
  max-height: 21.5rem;
  min-height: 16rem;
}
.lv-filter-menu .mat-menu-content {
  height: 100%;
}
.lv-filter-menu .mat-menu-content:not(:empty) {
  padding-bottom: 0;
}
.lv-filter-menu .mat-selection-list.lv-filter-list {
  height: calc(100% - 6.163rem);
  overflow-y: auto;
  padding-top: 0;
}
.lv-filter-menu .mat-selection-list.lv-filter-list--selected {
  height: calc(100% - 9.288rem);
}
.lv-filter-menu .mat-selection-list.lv-filter-list .lv-list-item {
  height: 2.5rem;
}
.lv-filter-menu .lv-filter-form {
  height: 100%;
  position: relative;
}
.lv-filter-menu .lv-filter-role-title {
  padding: 1rem 1rem 0 1rem;
  font-family: AvenirNext;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #343535;
}
.lv-filter-menu .lv-filter-role-select-button {
  padding: 0;
  min-width: 0;
  font-family: AvenirNext;
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  color: #757575;
}
.lv-filter-menu .mat-menu-content:not(:empty) {
  padding-top: 0;
}
.lv-filter-menu .lv-filter-footer {
  height: 3.125rem;
  padding: 0.563rem 0.437rem 0.563rem 1rem;
  border-top: 1px solid #cbcbcb;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  background-color: #ffffff;
}
.lv-filter-menu .lv-filter-apply-button {
  height: 2rem;
  padding: 0.5rem 0.5rem 0.375rem;
  border-radius: 16px;
  font-family: AvenirNext;
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
}
.lv-filter-menu .lv-selected-text {
  font-family: AvenirNext;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
}

.mat-dialog-container .mat-dialog-content {
  max-height: 95vh;
}

.lv-tooltip {
  background-color: #ffffff;
  color: #171717 !important;
  border-radius: 4px;
  padding: 8px 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.lv-tooltip-dark {
  background-color: #171717 !important;
  color: #ffffff !important;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.lv-checkbox .mat-checkbox-frame {
  border-color: #800202;
  background-color: inherit;
}
.lv-checkbox label {
  font-weight: bold;
}

.lv-checkbox:disabled .mat-checkbox-frame {
  border-color: #8e8e93;
  background-color: #800202;
}
.lv-checkbox:disabled label {
  font-weight: bold;
  color: #000000;
}

.lv-checkbox-large {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lv-checkbox-large .mat-checkbox-frame {
  border-color: #800202;
  background-color: inherit;
}
.lv-checkbox-large .mat-checkbox-inner-container {
  width: 24px;
  height: 24px;
}
.lv-checkbox-large label {
  font-weight: bold;
  font-size: 18px;
  color: #000000;
}

.lv-checkbox-large:disabled .mat-checkbox-frame {
  border-color: #8e8e93;
  background-color: #800202;
}
.lv-checkbox-large:disabled .mat-checkbox-inner-container {
  width: 24px;
  height: 24px;
}
.lv-checkbox-large:disabled .mat-checkbox-label {
  font-weight: bold;
  color: #000000;
  font-size: 18px;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: #171717;
}

.lv-disabled-checkbox .mat-checkbox-frame {
  border-color: #8e8e93;
  background-color: #800202;
}
.lv-disabled-checkbox label {
  font-weight: bold;
  color: #000000;
}

.lv-checkbox-normal .mat-checkbox-frame {
  border-color: #800202;
  background-color: inherit;
}
.lv-checkbox-normal label {
  font-weight: normal;
  size: 1rem;
  line-height: 1.313rem;
  color: #000000;
  text-transform: capitalize;
}

.lv-list-item .mat-list-text {
  font-weight: normal;
  size: 1rem;
  line-height: 1.313rem;
  color: #000000;
  text-transform: capitalize;
}
.lv-list-item .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  border-color: #800202;
  background-color: inherit;
  background: #800202 !important;
}
.lv-list-item .mat-pseudo-checkbox {
  border-color: #800202;
  background-color: inherit;
}

.mat-checkbox-checkmark-path {
  stroke: #ffffff !important;
}

.lv-offer-footer {
  margin: 0;
  width: 100%;
  border-top: 1px solid #cbcbcb;
  text-align: center;
  background-color: #ffffff;
  color: #000000;
  height: 5rem;
  box-sizing: border-box;
}

.lv-footer-wrapper {
  display: grid;
  grid-template-columns: 289px 1fr 289px;
  align-items: center;
  margin: 0 auto;
  height: 100%;
}
.lv-footer-wrapper button {
  max-width: 153px;
  height: 3rem;
}
@media (max-width: 575px) {
  .lv-footer-wrapper {
    max-width: calc(100% - 10px);
  }
}
@media (min-width: 576px) {
  .lv-footer-wrapper {
    max-width: calc(100% - 50px);
  }
}
@media (min-width: 768px) {
  .lv-footer-wrapper {
    max-width: calc(100% - 150px);
  }
}
@media (min-width: 992px) {
  .lv-footer-wrapper {
    max-width: calc(100% - 200px);
  }
}
@media (min-width: 1200px) {
  .lv-footer-wrapper {
    max-width: 1000px;
  }
}

.mat-select-panel {
  background: #ffffff !important;
}
.mat-select-panel .mat-option {
  color: #000000;
}
.mat-select-panel .mat-checkbox {
  color: #000000;
}
.mat-select-panel .mat-pseudo-checkbox {
  color: #000000;
}
.mat-select-panel .mat-option:hover {
  background: #fbd19c;
}

.mat-select-arrow {
  color: #000000;
}

.mat-select-value {
  color: #000000;
}

.lv-result-card {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  color: #000000;
}

.lv-short-answer-card {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
}
.lv-short-answer-card .lv-short-answer-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
}
.lv-short-answer-card .lv-short-answer-subtitle {
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  color: #343535;
}
.lv-short-answer-card .lv-short-answer-content {
  width: 100%;
  height: 100%;
}
.lv-short-answer-card .lv-short-answer-text-box {
  max-width: 230px;
  width: 100%;
  height: 125px !important;
}
.lv-short-answer-card .lv-short-answer-text-box .lv-short-answer-text {
  height: 100px;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.31;
  letter-spacing: normal;
  word-wrap: break-word;
  color: #000000;
}
.lv-short-answer-card .lv-short-answer-text-box .lv-short-answer-user {
  text-transform: capitalize;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.125rem;
  color: #000000;
}
.lv-short-answer-card .lv-short-answer-text-box .lv-short-answer-dispensary {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
}
.lv-short-answer-card .lv-short-answer-email-container {
  height: 175px;
  width: 100%;
  color: #000000;
  font-family: AvenirNext, sans-serif;
}
.lv-short-answer-card .lv-short-answer-email-container .lv-short-answer-results-nbr {
  font-size: 4.25rem;
  line-height: 5.5rem;
  font-weight: bold;
}
.lv-short-answer-card .lv-short-answer-email-container .lv-short-answer-respondents-nbr {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: bold;
}
.lv-short-answer-card .lv-short-answer-email-container .lv-short-answer-email-subline {
  text-align: center;
  font-size: 1.313rem;
  line-height: 1.688rem;
  font-weight: bold;
}
.lv-short-answer-card .lv-short-answer-button-box {
  height: 125px;
}

.lv-optional-label {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0 !important;
  font-size: 1rem;
  line-height: 1.313rem;
  letter-spacing: 0;
  color: #000000;
}

.lv-rating-card {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
}
.lv-rating-card .lv-rating-title {
  font-size: 24px;
  font-weight: bold;
  line-height: 31px;
  min-height: 70px;
}
.lv-rating-card .lv-rating-title-medium {
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  min-height: 70px;
}
.lv-rating-card .lv-rating-title-small {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  min-height: 70px;
}
.lv-rating-card .lv-rating-average-number {
  font-size: 68px;
  font-weight: bold;
  line-height: 88px;
}
.lv-rating-card .lv-rating-average-text {
  font-size: 21px;
  font-weight: bold;
  line-height: 27px;
}
.lv-rating-card .lv-rating-star-row {
  width: 100%;
  max-width: 160px;
}
.lv-rating-card .lv-rating-star-row mat-icon {
  font-size: 27px;
  height: 27px;
  width: 27px;
}
.lv-rating-card .lv-rating-content {
  width: 100%;
}

.lv-ranking-card {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #ffffff;
  color: #000000;
}
.lv-ranking-card .lv-ranking-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  margin-bottom: 10px;
}
.lv-ranking-card .lv-ranking-rows {
  width: 95%;
  height: 100%;
  max-height: 175px;
  text-transform: capitalize;
  font-size: 14px !important;
  font-weight: bold !important;
  overflow-y: hidden;
  overflow-x: auto;
}
.lv-ranking-card .lv-ranking-chart {
  text-transform: capitalize;
  font-size: 14px !important;
  font-weight: bold !important;
}

.lv-single-select-card {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
}
.lv-single-select-card .lv-single-select-title {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  margin-bottom: 10px;
}
.lv-single-select-card .lv-single-select-horizontal-chart {
  text-transform: capitalize;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center;
  width: 90%;
  padding-left: 10%;
  height: 150px;
}
.lv-single-select-card .lv-single-select-horizontal-chart .ngx-charts-bar-horizontal {
  padding-bottom: 100%;
  position: absolute;
}
.lv-single-select-card .lv-single-select-pie-chart {
  text-transform: capitalize;
  font-size: 14px !important;
  font-weight: bold !important;
  width: 90%;
  height: 100%;
}

.lv-multi-select-card {
  border-radius: 16px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #ffffff;
  color: #000000;
}
.lv-multi-select-card .lv-multi-select-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  margin-bottom: 10px;
}
.lv-multi-select-card .lv-multi-select-vertical-chart {
  text-transform: capitalize;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center;
  width: 90%;
  padding-left: 10%;
  height: auto;
  max-height: 175px;
}

.lv-matrix-card {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #ffffff;
  color: #000000;
}
.lv-matrix-card .lv-matrix-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
}
.lv-matrix-card .lv-matrix-subtitle {
  font-size: 14px;
  color: #343535;
  font-weight: normal;
  line-height: 18px;
  margin-bottom: 25px;
}
.lv-matrix-card .lv-matrix-content {
  width: 100%;
  overflow-y: auto;
}
.lv-matrix-card .lv-matrix-answer-row {
  padding-top: 5px;
  width: 100%;
  padding-left: 10%;
}
.lv-matrix-card .lv-matrix-answer-row .lv-matrix-answer-label {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  width: 50%;
  min-width: 120px;
  padding-right: 10px;
}
.lv-matrix-card .lv-matrix-answer-row .lv-matrix-star-row {
  width: 100%;
  max-width: 160px;
}
.lv-matrix-card .lv-matrix-answer-row .lv-matrix-star-row mat-icon {
  font-size: 27px;
  height: 27px;
  width: 27px;
}

.lv-file-upload-card {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
}
.lv-file-upload-card .lv-file-upload-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
}
.lv-file-upload-card .lv-file-upload-subtitle {
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  color: #343535;
}
.lv-file-upload-card .lv-file-upload-content {
  width: 100%;
  height: 100%;
}
.lv-file-upload-card .lv-file-upload-image-box {
  max-width: 125px;
  width: 100%;
  height: 100%;
  max-height: 125px;
  border-radius: 16px;
}
.lv-file-upload-card .lv-file-upload-image-box img {
  border-radius: 16px;
  width: auto;
  height: 100%;
  max-width: 125px;
  max-height: 160px;
}
.lv-file-upload-card .lv-file-upload-button-box {
  height: 125px;
}

.lv-grid-container {
  max-width: 1600px;
  padding-bottom: 50px;
  width: 80%;
  margin: auto;
}

.lv-grid-content {
  width: 100%;
  height: 100%;
}

.lv-result-grid mat-grid-tile {
  border-radius: 16px;
  background-color: #ffffff;
  color: #000000;
}

.advanced-pie-legend .total-value {
  padding-bottom: 10px;
}

.advanced-pie-legend .legend-items-container .legend-items {
  overflow: visible !important;
  white-space: nowrap;
}

.advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value {
  padding-bottom: 5px;
}

.mat-menu-item:hover {
  background-color: #fbd19c !important;
  color: #000000 !important;
}

::-webkit-scrollbar {
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 0.2rem;
  background-color: #cbcbcb;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.2rem;
  background-color: #fbd19c;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-corner,
::-webkit-resizer {
  display: none;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.lv-backdrop {
  background: #5c5d5d;
  opacity: 0.5 !important;
}

.cell-phone-outline {
  background-image: url("../../img/cellphone/cell-phone-outline.svg");
  background-repeat: no-repeat;
  width: 423px;
  box-sizing: border-box;
  height: 770px;
  padding: 52px 22px 51px 26px;
  object-fit: contain;
  margin-top: 10px;
}

.Rectangle-Copy {
  width: 375px;
  height: 670px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #171717;
}

.BarsStatusWhite {
  object-fit: contain;
  width: auto;
  height: auto;
}

.TopIconContainer {
  margin: 0 16px;
}

.BarBackLeft {
  width: 28px;
  height: 28px;
  object-fit: contain;
}

.ShareIconRight {
  width: 28px;
  height: 28px;
  object-fit: contain;
}

.preview-image-container {
  width: 343px;
  height: 187px;
  background-color: #cbcbcb !important;
  margin: 16px;
  border-radius: 5px;
}

.preview-image-icon {
  display: block;
  margin: auto auto;
  padding-top: 35px;
}

.preview-image-file {
  width: 343px;
  height: 187px;
  border-radius: 5px;
}

.preview-brand-name {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  margin: 0 16px;
  overflow-y: auto;
  max-height: 30px;
}

.preview-product-name {
  font-size: 17px;
  line-height: 22px;
  color: #ffffff;
  margin: 0 16px;
  overflow-y: auto;
  max-height: 25px;
}

.preview-license {
  font-size: 13px;
  line-height: 17px;
  color: #ffffff;
  margin: 0px 16px;
  letter-spacing: -0.08px;
  overflow-y: auto;
  max-height: 25px;
}

.preview-product-description {
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  margin: 0px 16px;
  overflow-y: auto;
  overflow-style: unset;
  height: 150px;
}

.preview-contest-product-description {
  font-size: 16px;
  line-height: 1.2;
  color: #ffffff;
  margin: 0px 16px;
  overflow-y: auto;
  overflow-style: unset;
  height: 150px;
}

.preview-fine-print {
  margin-top: 10px;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
}

.preview-fine-print-description {
  font-size: 16px;
  line-height: 1.2;
  color: #ffffff;
}

.preview-start-button {
  width: 343px;
  height: 48px;
  margin: 10px 16px 25px;
  object-fit: contain;
}

.bottom-icons-row {
  margin: 0px 16px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center space-between;
  align-items: center;
}

.offer-preview-tab-text {
  font-size: 12px;
  color: #8e8e93;
  line-height: 16px;
  text-align: center;
}

.lv-survey-wrapper {
  min-height: 625px;
  display: flex;
  flex-direction: column;
}

.lv-survey-container {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 64px 25px 64px;
}
@media (max-width: 575px) {
  .lv-survey-container {
    max-width: calc(100% - 20px);
  }
}
@media (min-width: 576px) {
  .lv-survey-container {
    max-width: calc(100% - 50px);
  }
}
@media (min-width: 768px) {
  .lv-survey-container {
    max-width: calc(100% - 150px);
  }
}
@media (min-width: 992px) {
  .lv-survey-container {
    max-width: calc(100% - 200px);
  }
}
@media (min-width: 1200px) {
  .lv-survey-container {
    max-width: 1000px;
  }
}
.lv-survey-container .lv-question-container {
  width: 100%;
  padding-bottom: 25px;
  padding-top: 25px;
}

.lv-post-survey-container {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px 64px 0 64px;
}
@media (max-width: 575px) {
  .lv-post-survey-container {
    max-width: calc(100% - 20px);
  }
}
@media (min-width: 576px) {
  .lv-post-survey-container {
    max-width: calc(100% - 50px);
  }
}
@media (min-width: 768px) {
  .lv-post-survey-container {
    max-width: calc(100% - 150px);
  }
}
@media (min-width: 992px) {
  .lv-post-survey-container {
    max-width: calc(100% - 200px);
  }
}
@media (min-width: 1200px) {
  .lv-post-survey-container {
    max-width: 1000px;
  }
}
.lv-post-survey-container .lv-post-tab-container-border {
  border-bottom: #9e9e9e 1px solid;
}
.lv-post-survey-container .lv-post-tab-container {
  width: 100%;
  padding-bottom: 15px;
}
.lv-post-survey-container .lv-post-tab-container .lv-post-tab-title {
  width: 200px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}
.lv-post-survey-container .lv-post-tab-container .lv-post-tab-subtitle {
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
}
.lv-post-survey-container .lv-post-tab-container .lv-post-tab-info-icon {
  margin-bottom: 15px;
}
.lv-post-survey-container .lv-question-container {
  width: 100%;
  padding-bottom: 25px;
  padding-top: 25px;
}

.lv-question-box {
  align-self: center;
  width: 100%;
  border: 2px solid #171717;
  padding-right: 34px;
  padding-left: 14px;
  border-radius: 8px;
  height: 80px;
  text-align: center;
  max-width: 1000px;
}

.lv-question-input-row {
  margin-top: 10px;
  width: 100%;
}
.lv-question-input-row .lv-question-number {
  font-size: 21px;
  font-weight: bold;
  line-height: 27px;
}

.lv-question-input-label {
  max-width: 500px;
  width: 100%;
}
.lv-question-input-label .mat-form-field {
  width: 100%;
  padding-bottom: 0;
}

.lv-question-input-type {
  width: 100%;
  max-width: 200px;
}
.lv-question-input-type .mat-form-field {
  width: 100%;
  padding-bottom: 0;
}

.lv-question-input-type-edit {
  max-width: 200px;
  padding-bottom: 15px;
  padding-left: 5%;
  line-height: 1.75;
}
.lv-question-input-type-edit .lv-question-input-type-label {
  font-size: 10px;
  font-weight: bold;
}

.lv-question-single-box {
  align-self: center;
  width: 100%;
  border: 2px solid #171717;
  padding-right: 34px;
  padding-left: 14px;
  border-radius: 8px;
  min-height: 830px;
  text-align: center;
  max-width: 1000px;
}

.lv-question-single-box-display {
  align-self: center;
  width: 100%;
  border: 2px solid #171717;
  padding-right: 34px;
  padding-left: 14px;
  border-radius: 8px;
  min-height: 80px;
  text-align: center;
  max-width: 1000px;
}

.lv-question-display {
  font-size: 18px;
  width: 75%;
  text-align: left;
  color: #171717;
}

.lv-question-title {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: bold;
  text-align: left;
  margin-left: 6%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.lv-select-answer-row {
  margin-left: 6%;
}
.lv-select-answer-row .mat-icon-button.mat-button-disabled.mat-button-disabled {
  color: #cbcbcb;
}

.lv-question-text-input {
  width: 80%;
}

.lv-question-checkbox-row {
  width: 100%;
  text-align: left;
  margin-left: 6%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.lv-question-checkbox-row .mat-checkbox {
  padding-left: 12px;
  font-size: 16px;
  font-weight: bold;
}
.lv-question-checkbox-row .mat-checkbox .mat-checkbox-frame {
  border-color: #800202;
  background-color: #ffffff;
}
.lv-question-checkbox-row .mat-checkbox .mat-checkbox-frame .mat-checked {
  border-color: #800202;
  background-color: #800202;
}

.lv-question-buttons-row {
  width: 94%;
  text-align: right;
  margin-left: 6%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.lv-conditional-section {
  margin-left: 6%;
}

.lv-conditional-row {
  max-width: 600px;
}

.lv-conditional-label {
  text-align: left;
  width: 50%;
  max-width: 268px;
  font-size: 18px;
  color: #000000;
  line-height: 24px;
}

.lv-conditional-select {
  width: 50%;
  max-width: 268px;
}

.lv-question-validation-section {
  margin-left: 6%;
}

.lv-question-preview-image-container {
  width: 180px;
  height: auto;
  max-height: 180px;
  border-radius: 8px;
  padding-bottom: 10px;
}
.lv-question-preview-image-container img {
  border-radius: 8px;
  width: 180px;
  height: auto;
  max-height: 180px;
}

.lv-question-edit-content {
  margin-left: 6%;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}

/* Drag and Drop. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  background: transparent;
}

.lv-drag-custom-placeholder {
  background: #fbd19c;
  border: #8e8e93 3px #cbcbcb;
  border-radius: 4px;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.lv-offer-edit-container {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 50px 64px 0 64px;
}
@media (max-width: 575px) {
  .lv-offer-edit-container {
    max-width: calc(100% - 20px);
  }
}
@media (min-width: 576px) {
  .lv-offer-edit-container {
    max-width: calc(100% - 50px);
  }
}
@media (min-width: 768px) {
  .lv-offer-edit-container {
    max-width: calc(100% - 150px);
  }
}
@media (min-width: 992px) {
  .lv-offer-edit-container {
    max-width: calc(100% - 200px);
  }
}
@media (min-width: 1200px) {
  .lv-offer-edit-container {
    max-width: 1000px;
  }
}

.lv-offer-edit-form {
  padding-bottom: 30px;
}
.lv-offer-edit-form .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.7em 0;
}

.lv-upload-container {
  width: 328px;
  height: 184px;
  padding: 31px 43px 33px;
  border-radius: 16px;
  border: solid 3px #171717;
  border-style: dashed;
  margin-bottom: 32px;
}
.lv-upload-container .lv-upload-icon {
  width: 64px;
  height: 64px;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}
.lv-upload-container .lv-upload-text {
  width: 242px;
  font-family: AvenirNext, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
}

.lv-offer-jobs ::ng-deep .mat-select-value {
  color: #ffffff !important;
}

.lv-metrics-container {
  background: linear-gradient(to bottom, #171717 0%, #171717 50%, #000000 50%, #000000 100%);
  padding: 0 10%;
}

.lv-metric-card {
  cursor: inherit;
  background: #222222;
  border-radius: 16px;
  width: 50%;
  max-height: 109px;
  padding: 15px;
}

.lv-metric-title {
  font-size: 1rem;
  color: #ffffff;
  font-weight: bold;
  line-height: 1.31;
}

.lv-metric-value-container {
  color: #ffffff;
}
.lv-metric-value-container .mat-icon {
  height: 2.5rem;
  width: 2.5rem;
  font-size: 2.5rem;
  margin-left: 0.375rem;
}

.lv-metric-subvalue {
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.29;
  margin-top: 1rem;
  font-weight: bold;
}

.lv-metric-value {
  color: #ffffff;
  font-size: 50px;
  line-height: 65px;
  font-weight: bold;
}

.lv-metric-icon {
  width: 32px !important;
  height: 32px !important;
}

.lv-leader-metrics-container {
  background: linear-gradient(to bottom, #171717 0%, #171717 50%, #171717 50%, #171717 100%);
  min-height: 125px;
  padding: 0 10%;
}

.lv-leader-metrics-child {
  margin-top: 24px;
  background: none;
}

.lv-leader-metrics-row {
  width: 100%;
}

.lv-stat-card {
  background: #222222;
  border-radius: 16px;
  width: 50%;
  max-height: 109px;
  padding: 15px;
}

.lv-stat-value-container {
  color: #800202;
}
.lv-stat-value-container .mat-icon {
  height: 2.5rem;
  width: 2.5rem;
  font-size: 2.5rem;
  margin-left: 0.375rem;
}

.lv-stat-subvalue {
  color: #800202;
  font-size: 0.875rem;
  line-height: 1.29;
  margin-top: 1rem;
  font-weight: bold;
}

.lv-stat-title {
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  line-height: 26px;
  padding-bottom: 10px;
}

.lv-stat-value {
  color: #800202;
  font-size: 50px;
  line-height: 65px;
  font-weight: bold;
}

.lv-stat-icon {
  width: 32px !important;
  height: 32px !important;
}

.lv-details-nav {
  width: 80%;
  margin: auto;
  margin-top: 1.125rem;
}

.lv-details-title {
  color: #ffffff;
  font-size: 22px;
  line-height: 29px;
  font-weight: bold;
}

.lv-detail-tab {
  color: #ffffff;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  font-weight: 550;
  cursor: pointer;
  padding-bottom: 10px;
}

.lv-detail-tab-active {
  color: #800202;
  border-bottom: 2px solid #800202;
  padding-bottom: 10px;
}

.lv-filter-row {
  margin-bottom: 10px;
}
.lv-filter-row .mat-menu {
  background: #ffffff;
  color: #000000;
}

.lv-filter-response {
  padding: 11px 16px 9px 11px;
  color: #ffffff !important;
  cursor: pointer;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  border: 2px solid #ffffff;
}
.lv-filter-response input[type=text] {
  cursor: pointer;
  color: #ffffff;
  background: #000000;
  border: none;
  outline: none;
  font-family: AvenirNext;
  font-size: 1.063rem;
  font-weight: 600;
  line-height: 1.29;
}
.lv-filter-response ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1;
  /* Firefox */
}
.lv-filter-response ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ffffff;
}
.lv-filter-response input[type=text]:focus {
  outline: none;
  color: #ffffff;
  border: none;
}

.lv-filter-title {
  color: #000000;
  min-width: 250px;
  padding: 0 10px;
}

.mat-menu-panel {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  width: 260px;
}
.mat-menu-panel ::-webkit-scrollbar {
  display: none !important;
}

.lv-nested-menu {
  background: #ffffff !important;
  color: #171717 !important;
}
.lv-nested-menu .mat-menu-item {
  background: transparent;
  color: #171717 !important;
}
.lv-nested-menu .mat-menu-item-submenu-trigger::after {
  background: transparent;
  color: #171717 !important;
}
.lv-nested-menu form {
  color: #171717 !important;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-nested-menu form ::-webkit-scrollbar {
  display: none !important;
}

.lv-leader-date-range-form {
  margin-right: auto;
  margin-left: 1rem;
}
.lv-leader-date-range-form .lv-date-range-title {
  font-weight: bold;
  margin-bottom: 1rem;
}
.lv-leader-date-range-form mat-form-field {
  width: calc(100% - 10px);
}
.lv-leader-date-range-form button {
  text-align: center;
  width: calc(100% - 5px);
  margin-bottom: 1rem;
}

.lv-offer-filter-row .mat-menu {
  background: #ffffff;
  color: #000000;
}

.lv-filter-offers {
  padding: 11px 16px 9px 11px;
  color: #ffffff !important;
  cursor: auto;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  border: 2px solid #ffffff;
}
.lv-filter-offers input[type=text] {
  cursor: auto;
  color: #ffffff;
  background: #171717;
  border: none;
  outline: none;
  font-family: AvenirNext;
  font-size: 1.063rem;
  font-weight: 600;
  line-height: 1.29;
}
.lv-filter-offers ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1;
  /* Firefox */
}
.lv-filter-offers ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ffffff;
}

.lv-filter-offers-focus {
  padding: 11px 16px 9px 11px;
  color: #171717 !important;
  background-color: #ffffff !important;
  cursor: pointer;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  border: 2px solid #ffffff;
}
.lv-filter-offers-focus .mat-icon {
  width: 25px;
}
.lv-filter-offers-focus input[type=text] {
  cursor: auto;
  background: #ffffff;
  color: #171717;
  border: none;
  outline: none;
  font-size: 17px;
}
.lv-filter-offers-focus ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #171717;
  opacity: 1;
  /* Firefox */
}
.lv-filter-offers-focus ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #171717;
}

.lv-filter-title {
  color: #000000;
  min-width: 250px;
}

.mat-menu-panel {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  width: 260px;
}
.mat-menu-panel ::-webkit-scrollbar {
  display: none !important;
}

.lv-nested-menu {
  background: #ffffff !important;
  color: #171717 !important;
  padding-right: 5px !important;
}
.lv-nested-menu .mat-menu-item {
  background: transparent;
  color: #171717 !important;
}
.lv-nested-menu .mat-menu-item-submenu-trigger::after {
  background: transparent;
  color: #171717 !important;
}
.lv-nested-menu form {
  color: #171717 !important;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.lv-nested-menu form ::-webkit-scrollbar {
  display: none !important;
}

.lv-export-layout .export-question-title {
  font-weight: bold;
  font-size: 1.2rem;
  max-width: 700px;
  margin-top: 20px;
}
.lv-export-layout .export-question-subtitle {
  max-width: 700px;
  margin: 0 !important;
  padding-bottom: 10px;
}
.lv-export-layout div {
  max-width: 700px;
}
.lv-export-layout table {
  width: 700px;
  border-collapse: collapse;
}
.lv-export-layout table,
.lv-export-layout th,
.lv-export-layout td {
  border: 1px solid black;
}

.lv-dispensary-grid {
  margin: 20px auto;
  width: 80%;
}

@media (max-width: 575px) {
  .lv-dispensary-leaderboard {
    min-height: 225px;
    padding: 10px;
  }
}
@media (min-width: 576px) {
  .lv-dispensary-leaderboard {
    padding-left: 25px;
    padding-right: 25px;
    min-height: 125px;
  }
}
@media (min-width: 768px) {
  .lv-dispensary-leaderboard {
    padding-left: 75px;
    padding-right: 75px;
    min-height: 150px;
  }
}
@media (min-width: 992px) {
  .lv-dispensary-leaderboard {
    padding-left: 200px;
    padding-right: 200px;
    min-height: 175px;
  }
}
@media (min-width: 1200px) {
  .lv-dispensary-leaderboard {
    padding-left: 256px;
    padding-right: 256px;
    min-height: 200px;
  }
}

.lv-dispensary-tile {
  border-radius: 8px;
  background-color: #222222;
  text-align: left;
  cursor: pointer;
}

.lv-dispensary-container {
  padding: 16px 19px 18px 16px;
  width: 100%;
}

.lv-dispensary-left {
  width: 75%;
  text-align: left;
}

.lv-dispensary-right {
  width: 25%;
  text-align: center;
}
.lv-dispensary-right .lv-dispensary-employee {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.31;
}
.lv-dispensary-right .lv-dispensary-count {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.29;
  color: #ffffff;
}

.lv-dispensary-tile-name {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: #ffffff;
}

.lv-dispensary-tile-address {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  color: #9e9e9e;
}

.team-review-nav-container {
  width: 100%;
  padding-top: 35px;
}
@media (max-width: 575px) {
  .team-review-nav-container {
    padding-left: 10px;
    margin: 0;
  }
}
@media (min-width: 576px) {
  .team-review-nav-container {
    padding-left: 50px;
    margin: 0;
  }
}
@media (min-width: 768px) {
  .team-review-nav-container {
    padding-left: 100px;
    margin: 0;
  }
}
@media (min-width: 992px) {
  .team-review-nav-container {
    padding-left: 150px;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .team-review-nav-container {
    padding-left: 250px;
    margin: 0;
  }
}

.team-review-nav-logo {
  width: 56px;
  height: 56px;
  margin: 0 8px 16px 0;
  object-fit: contain;
}

.team-review-close-icon {
  width: 56px;
  height: 56px;
}

.team-review-nav-column {
  width: 100%;
  max-width: 1025px;
}

.team-review-nav-title {
  margin: 7px;
  font-family: AvenirNext, sans-serif;
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.team-review-nav-description {
  margin: 7px;
  font-family: AvenirNext, sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.team-review-edit-container {
  width: 100%;
  max-width: 1600px;
  margin: 0;
  padding-top: 50px;
}

.team-review-edit-form {
  padding-bottom: 30px;
}
.team-review-edit-form .mat-form-field {
  width: 75%;
}
.team-review-edit-form .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 1em 0;
}
@media (max-width: 575px) {
  .team-review-edit-form {
    padding-left: 10px;
    margin: 0;
  }
}
@media (min-width: 576px) {
  .team-review-edit-form {
    padding-left: 25px;
    margin: 0;
  }
}
@media (min-width: 768px) {
  .team-review-edit-form {
    padding-left: 75px;
    margin: 0;
  }
}
@media (min-width: 992px) {
  .team-review-edit-form {
    padding-left: 200px;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .team-review-edit-form {
    padding-left: 256px;
    margin: 0;
  }
}

.team-review-badge-container img {
  height: 100%;
  width: 100%;
  max-width: 222px;
  max-height: 345px;
  cursor: pointer;
}

.team-review-badge-dialog {
  text-align: center;
}
.team-review-badge-dialog img {
  height: auto;
  width: 100%;
  max-width: 550px;
  max-height: 400px;
}

.team-review-actions {
  width: 100%;
  margin-bottom: 50px;
  max-width: 1400px;
}

.team-action-buttons {
  padding-right: 50px;
}