@import 'configurable-values';
@import 'mixins';

.lv-breadcrumb {
  height: 2.75rem;
  margin: 0 -0.438rem 1.625rem -0.438rem;
  color: $tint-primary;
  button {
    color: $tint-primary;
    padding: 0 0.438rem;
  }
}
