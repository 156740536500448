// Global color config
$dark-grey: #8e8e93;
$tint-primary: #800202;
$tint-primary-50: #fbd19c;
$tint-secondary: #6888cf;
$nuetrals-black-pure: #000000;
$nuetrals-grey-disabled: #555659;
$nuetrals-grey-dark: #757575;
$nuetrals-unselected-grey: #818284;
$nuetrals-grey-1: #5c5d5d;
$nuetrals-grey-4: #9e9e9e;
$nuetrals-grey-5: #cbcbcb;
$nuetrals-grey-6: #f5f5f5;
$nuetrals-white: #ffffff;
$pastel-red: #c96664;
$background-tux-darkest: #222222;
$tux: #171717;
// $background-tux-lightest: #212631;
$background-tux-lightest: #171717;
$background-tux: #171717;
$old-pine: #026347;
$approve-green: #84d27c;
$old-tint-orange: #ff7e5e;
$tint-orange: #fbd19c;

// Smartphones (landscape view)
$screen-xs-max: 575px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1280px;

// Global
$maxWidth: 1000px;
$content-width: 80%;

$spacing-xxs: 0.25rem;
$spacing-xs: 0.5rem;
$spacing-s: 0.75rem;
$spacing-m: 1rem;
$spacing-l: 1.5rem;
$spacing-xl: 2rem;
$spacing-xxl: 2.5rem;
$spacing-xxxl: 3rem;

$footer-height: 5rem;
