@import 'configurable-values';

.lv-light-dialog {
  mat-dialog-container {
    background: $nuetrals-white !important;
    color: $tux !important;

    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
    ::-webkit-scrollbar {
      display: none !important;
    }

    .mat-dialog-content {
      -ms-overflow-style: none !important; /* IE and Edge */
      scrollbar-width: none !important; /* Firefox */
      ::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
}

.lv-light-dialog-rounded {
  mat-dialog-container {
    background: $nuetrals-white !important;
    color: $tux !important;
    border-radius: 0.75rem !important;

    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
    ::-webkit-scrollbar {
      display: none !important;
    }

    .mat-dialog-content {
      -ms-overflow-style: none !important; /* IE and Edge */
      scrollbar-width: none !important; /* Firefox */
      ::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
}

.lv-transparent-dialog-rounded {
  mat-dialog-container {
    background: transparent;
    color: $nuetrals-white;
    border-radius: 18px;
    padding: 0;

    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
    ::-webkit-scrollbar {
      display: none !important;
    }

    .mat-dialog-content {
      -ms-overflow-style: none !important; /* IE and Edge */
      scrollbar-width: none !important; /* Firefox */
      ::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
}

.lv-dark-dialog {
  mat-dialog-container {
    background: $tux !important;
    color: $nuetrals-white !important;

    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
    ::-webkit-scrollbar {
      display: none !important;
    }

    .mat-dialog-content {
      -ms-overflow-style: none !important; /* IE and Edge */
      scrollbar-width: none !important; /* Firefox */
      ::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
}

.mat-dialog-content {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  ::-webkit-scrollbar {
    display: none !important;
  }
}

// Results Dialog

.lv-dialog-wrapper {
  @include xs {
    padding-left: 10px;
    padding-right: 10px;
    height: 125px;
  }

  @include sm {
    padding-left: 25px;
    padding-right: 25px;
    height: 125px;
  }

  @include md {
    padding-left: 75px;
    padding-right: 75px;
    height: 150px;
  }

  @include lg {
    padding-left: 200px;
    padding-right: 200px;
    height: 175px;
  }

  @include xl {
    padding-left: 256px;
    padding-right: 256px;
    height: 200px;
  }
}

.lv-dialog-title {
  font-size: 35px;
  font-weight: bold;
  color: $nuetrals-white;

  mat-icon {
    color: $nuetrals-white;
    font-size: 56px;
    height: 56px;
    width: 56px;
  }
}

// Offer Type Dialog

.lv-offer-type-title-container {
  margin-bottom: 16px;
}

.lv-offer-type-title {
  font-size: 26px !important;
  font-weight: bold !important;
}

.lv-offer-type-description {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
}

.lv-offer-type-square {
  margin-top: 30px !important;
}

// Offer Edit Companies Dialog
.lv-dispensary-title-container {
  &.mat-dialog-title {
    margin-bottom: 0;
  }
}

.lv-dispensary-title {
  font-size: 26px !important;
  font-weight: bold !important;
}

.lv-dispensary-title-description {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 3rem;
}

.lv-dispensary-list-container {
  // ToDo: Need to make this responsive
  width: 404px;
  height: 454px;
  border-radius: 16px;
  border: solid 2px $nuetrals-grey-5;
  background-color: $nuetrals-white;

  overflow: auto;

  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.lv-dispensary-list-container-inner {
  height: 100%;
  width: 100%;
  max-height: 425px;
  overflow: auto;

  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.lv-dispensary-list-items {
  height: 100%;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.lv-dispensary-list-items::-webkit-scrollbar {
  display: none !important;
}

.lv-dispensary-list-add-buttons {
  width: 100%;
}

.lv-dispensary-list-buttons {
  max-width: 1600px;
  margin-top: 30px;
  padding-right: 26px;
}

.lv-dispensary-selection-list-buttons {
  max-width: 1600px;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-right: 25rem;
}

.lv-dispensary-list-title {
  width: 100%;
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.438rem;
  padding-bottom: 0.5rem;
  height: 3.125rem;
}

.lv-dispensary-list-item {
  width: 100%;
  height: 40px;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}

.lv-dispensary-item-selected {
  background-color: $tint-primary-50;
  font-weight: bold;
  width: 100%;
}

.lv-product-list-container {
  // ToDo: Need to make this responsive
  width: 404px;
  height: 454px;
  border-radius: 16px;
  border: solid 2px $nuetrals-grey-5;
  background-color: $nuetrals-white;

  overflow: auto;

  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.lv-product-list-title {
  margin-top: 0.5rem;
  max-width: 100%;
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

// Employee dialog
.lv-employee-list-title {
  width: 100%;
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;
  padding: 10px 16px;
  height: 3.125rem;
}

.lv-employee-select-buttons {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding: 0.563rem 0.437rem 0.563rem 1rem;
  border-top: 1px solid $nuetrals-grey-5;
  height: 3.125rem;

  .mat-button {
    font-size: 0.875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    padding: 0.5rem 0.5rem 0.375rem;
    height: 2rem;
    min-width: 2.813rem;
  }
}

// Results dialog

.lv-dialog-result-title {
  font-size: 28px;
  font-weight: bold;
  line-height: 37px;
}

.lv-dialog-result-subtitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
}

.lv-dialog-result-chart-row {
  width: 90%;
  height: 275px;
  margin-left: auto;
  margin-right: auto;
}

.lv-dialog-result-matrix-row {
  width: 90%;
  // height: 275px;

  .lv-matrix-result-answer-label {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    width: 240px;
    padding-right: 10px;
  }
}

.lv-dialog-rating-section {
  padding-bottom: 30px;

  .lv-rating-average-number {
    font-size: 68px;
    font-weight: bold;
    line-height: 88px;
  }

  .lv-rating-average-text {
    font-size: 21px;
    font-weight: bold;
    line-height: 27px;
  }

  .lv-rating-star-row {
    width: 100%;
    max-width: 160px;

    mat-icon {
      font-size: 27px;
      height: 27px;
      width: 27px;
    }
  }
}

.lv-dialog-result-pinned-title {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 15px;
}

.lv-dialog-result-pinned-answer {
  width: 250px;
  height: 290px;
  padding: 10px;
  padding-left: 50px;
}

.lv-dialog-result-upload-box {
  margin: 5px;
  width: auto;
  height: 140px;
  border-radius: 16px;

  img {
    border-radius: 16px;
    width: auto;
    height: 140px;
  }

  video {
    border-radius: 16px;
    width: auto;
    height: 140px;
  }
}

.lv-dialog-result-search {
  .lv-filter-results-focus {
    color: $nuetrals-black-pure !important;
    border: 1px solid $nuetrals-black-pure !important;

    mat-icon {
      color: $nuetrals-black-pure !important;
    }
  }

  .lv-filter-results {
    padding: 0.75rem;
    color: $nuetrals-grey-5 !important;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    border: 1px solid $nuetrals-grey-5;
    width: auto;

    input[type='text'] {
      color: $nuetrals-grey-5;
      background: $nuetrals-white;
      border: none;
      outline: none;
      font-size: 17px;
      width: 80%;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $nuetrals-grey-5;
      opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $nuetrals-grey-5;
    }

    input[type='text']:focus {
      outline: none;
      color: $nuetrals-black-pure;
      border: none;
    }

    input[type='text']:disabled {
      outline: none;
      color: $nuetrals-grey-disabled;
      border: none;
    }
  }

  .lv-search-empty {
    margin-top: 150px;
  }

  .lv-search-empty-title {
    font-size: 1.313rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.688rem;
    color: $nuetrals-black-pure;
  }

  .lv-search-empty-subtitle {
    font-size: 1.125rem;
    font-weight: normal;
    text-align: center;
    line-height: 1.5rem;
    color: $nuetrals-black-pure;
  }
}

.lv-search-response {
  text-wrap: normal;
  width: 250px;
  height: auto;
}

.lv-email-form {
  width: 100%;
}

.lv-add-team-form {
  width: 100%;
  margin: 20px;

  .mat-form-field {
    min-width: 80% !important;
  }
}

.lv-add-member-form {
  width: 100%;
  margin-right: 20px;

  .mat-form-field {
    min-width: 80% !important;
  }
}

.lv-employee-empty-state {
  width: 100%;
  font-size: 1rem;
  line-height: 27px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  box-sizing: border-box;
  margin: auto;
}

/** Whisper Blast from results dialog */

.lv-filter-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  width: 100%;
}

.lv-question-label-blast {
  font-size: 1.063rem;
  font-weight: bold;
  line-height: 1.375rem;
  color: $nuetrals-black-pure;
  padding-top: 0.5rem;
  padding-left: 16px;
  padding-right: 16px;
}

.lv-question-rating-filter {
  padding-top: 0.5rem;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 1.063rem;
  font-weight: bold;
  line-height: 1.375rem;
  margin-left: auto;
  margin-right: auto;
}

.lv-question-radio-container {
  padding-top: 0.5rem;
  padding-left: 16px;
  padding-right: 16px;
}

.lv-question-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}

.lv-question-radio-button {
  margin: 5px;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

// Leaderboard dispensary dialog

.lv-dispensary-stat-card {
  background: $nuetrals-grey-6;
  border-radius: 16px;
  width: 50%;
  max-height: 109px;
  padding: 15px;
}

.lv-dispensary-stat-value-container {
  color: $nuetrals-black-pure;
  font-size: 14px;
  line-height: 18px;

  .mat-icon {
    height: 24px;
    width: 24px;
    line-height: 1.5;
    position: relative;
    top: 5px;
  }
}

.lv-dispensary-stat-title {
  font-size: 20px;
  color: $nuetrals-black-pure;
  font-weight: bold;
  line-height: 26px;
  padding-bottom: 10px;
}

.lv-dispensary-stat-value {
  color: $nuetrals-black-pure;
  font-size: 50px;
  line-height: 65px;
  font-weight: bold;
}

.lv-dispensary-stat-icon {
  width: 32px !important;
  height: 32px !important;
}

.lv-filter-button {
  &.mat-icon-button {
    width: 36px;
    height: 36px;
    line-height: 36px;

    .lv-filter-icon {
      &.mat-icon {
        width: 28px;
        height: 28px;
        line-height: 28px;
        font-size: 28px;
      }
    }
  }
}

.lv-filter-menu {
  &.mat-menu-panel {
    max-height: 21.5rem;
    min-height: 16rem;
  }

  .mat-menu-content {
    height: 100%;

    &:not(:empty) {
      padding-bottom: 0;
    }
  }

  .mat-selection-list {
    &.lv-filter-list {
      height: calc(100% - 6.163rem);
      overflow-y: auto;
      padding-top: 0;

      &--selected {
        height: calc(100% - 9.288rem);
      }

      .lv-list-item {
        height: 2.5rem;
      }
    }
  }

  .lv-filter-form {
    height: 100%;
    position: relative;
  }

  .lv-filter-role-title {
    padding: 1rem 1rem 0 1rem;
    font-family: AvenirNext;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #343535;
  }

  .lv-filter-role-select-button {
    padding: 0;
    min-width: 0;
    font-family: AvenirNext;
    font-size: 0.875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    color: $nuetrals-grey-dark;
  }

  .mat-menu-content:not(:empty) {
    padding-top: 0;
  }

  .lv-filter-footer {
    height: 3.125rem;
    padding: 0.563rem 0.437rem 0.563rem 1rem;
    border-top: 1px solid $nuetrals-grey-5;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    background-color: $nuetrals-white;
  }

  .lv-filter-apply-button {
    height: 2rem;
    padding: 0.5rem 0.5rem 0.375rem;
    border-radius: 16px;
    font-family: AvenirNext;
    font-size: 0.875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
  }

  .lv-selected-text {
    font-family: AvenirNext;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
  }
}

.mat-dialog-container {
  .mat-dialog-content {
    max-height: 95vh;
  }
}
